/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-09 11:54:13
 */
import { Button, TableColumnType } from 'antd'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { Dimen } from '../../../../models/dimen'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'
import React from 'react'
import { getPietestStateName } from '../../../../models/pitest'
export const Columns = (
  startIndex: number,
  onclick: (t: any, id: number) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: Dimen.Name,
      key: 'patientName',
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
    },
    {
      title: '性别',
      key: 'gender',
      align: 'center',
      width: Dimen.Sex,
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      key: 'patientAge',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '就诊时间',
      dataIndex: 'registrationTime',
      align: 'center',
      width: Dimen.Time,
      key: 'registrationTime',
      render: (_, r) => {
        return moment(r.registrationTime).format(DateTimeFormatSimple)
      },
    },
    {
      title: '皮试药品名称',
      dataIndex: 'drugName',
      align: 'center',
      width: Dimen.Name,
      key: 'drugName',
    },
    {
      title: '皮试登记人',
      dataIndex: 'testUserName',
      width: Dimen.Name,
      align: 'center',
      key: 'testUserName',
    },
    {
      title: '皮试时间',
      dataIndex: 'actualTime',
      align: 'center',
      width: Dimen.Time,
      key: 'actualTime',
      render: (_, r: any) => {
        return r?.actualTime != 0 ? r?.actualTime  : '-'
      },
    },
    {
      title: '皮试结果',
      dataIndex: 'result',
      align: 'center',
      width: Dimen.Result,
      key: 'result',
      render: (_, r: any) => {
        return r?.result?.toString() ? getPietestStateName(r?.result?.toString()) : '-'
      },
    },
    {
      title: '操作',
      align: 'center',
      render: function element(_, t) {
        return (
          <Button
            // disabled={
            //   t?.recipeState == -1 || t?.recipeState == 0 ? false : true
            // }
            type='primary'
            size='small'
            onClick={(e: any) => {
              onclick(t, t.id)
            }}
          >
            修改
          </Button>
        )
      },
    },
  ]
}
