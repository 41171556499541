/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-19 18:55:23
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Checkbox,
  Col, Popover,
} from 'antd'
import { unwatchFile } from 'fs'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantCountyCode,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {QuestionCircleTwoTone} from "@ant-design/icons";

export const Query = (props: {
  onValueChange: (params: any) => void
  print: () => void
  export: () => void
}): ReactElement => {
  const [form] = Form.useForm()

  const { Option } = Select

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [flag, setFlag] = useState<any>()

  const [timeList, setTimeList] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  useEffect(() => {
    setTimeList([moment().startOf('month'), moment().endOf('day')])
    form.setFieldsValue({
      time: [moment().startOf('month'), moment().endOf('day')],
    })
    form.submit()
  }, [tenantCountyCode])



  // const disabledDate: any = (current: any) => {
  //   return timeList?.[0]
  //     ? moment(current)?.format('yyyy') != moment(timeList?.[0])?.format('yyyy')
  //     : false
  // }

  return (
    <Form
      form={form}
      initialValues={{
        searchWay: true, // 默认勾选排除离休
        yidibz: 0 // 默认勾选本地
      }}
      onFinish={(values) => {
        const startTime = moment(values?.time?.[0])?.startOf('day')?.format(
          DateTimeFormatSimpleOnlyOne
        )
        const endTime = moment(values?.time?.[1])?.endOf('day')?.format(
          DateTimeFormatSimpleOnlyOne
        )
        props.onValueChange({
          ...values,
          timeHead: startTime,
          timeTail: endTime,
          time: undefined,
          searchWay:values?.searchWay?"except340":"none"

        })
      }}
    >
      <Row style={{ marginLeft: 4, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='time' noStyle>
            <RangePicker
              // format={DateTimeFormatSimple}
              style={{ width: '16em' }}
              onChange={(v: any) => {
                setTimeList(v)
                form.submit()
              }}
            />
          </Form.Item>
          {insuranceArray.length !== 0 && (
            <>
              <Form.Item name='yidibz' noStyle>
                <Select
                  style={{ width: '140px' }}
                  placeholder='医保险种'
                  onChange={form.submit}
                >
                  <Option value={0} key={0}>
                    本地
                  </Option>
                  <Option value={3} key={3}>
                    省医保
                  </Option>
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item name='searchWay' valuePropName='checked' noStyle>
            <Checkbox
              onChange={() => {
                form.submit()
              }}
            >
              排除离休患者
            </Checkbox>
          </Form.Item>
          <Popover
              content={
                <div>
                  <p>
                    一般情况下，医保两定平台的统计表格不包含离休数据。
                  </p>
                  <p>
                    如果您想与医保两定平台中的【定点医疗机构医保数据查询】数据进行核对时，您需要排除离休患者
                  </p>
                </div>
              }
              placement='bottom'
              title='用量计算比：'
          >
            <QuestionCircleTwoTone />
          </Popover>
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
        <Space>
          <span>核拨表的金额仅包含处方明细中:收费类别为医保或历年的金额</span>
          <Button
            type='primary'
            onClick={() => {
              props?.print()
            }}
          >
            打印
          </Button>
          <Button
            type='primary'
            onClick={() => {
              props?.export()
            }}
          >
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
