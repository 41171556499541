import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Checkbox,
  Popconfirm,
  notification,
  Switch,
  Popover,
} from 'antd'
import React, { useEffect, useState } from 'react'
import styles from '../commodity/Commodity.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { isSameName } from '../../utils/fieldsUtils'
import {
  QuestionCircleTwoTone,
  CaretDownFilled,
  CaretUpFilled,
  CheckCircleOutlined,
} from '@ant-design/icons'
import {
  getCommonCodeCompletions,
  ItemCompletionType,
  selectCommonCodes,
} from '../completion/completionSlice'
import { CommonCodeCategory } from '../../models/commoncode'
import {
  getCommodityDetail,
  saveCommodityAsync,
  selectCommodityDetail,
  selectCommodityId,
  setSelectId,
  setCommodityDetail,
  getShopGoodsNo,
  selectShopNo,
  getZjWbCodes,
  logoutOrenableCommodity,
  checkAsync,
} from '../commodity/commoditySlice'
import cnchar from 'cnchar'
import {
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../models/billingCategory'
import {
  getChrgitmLvName,
  getMaterialsCategoryName,
  getMaterialsName,
  Materiales,
} from '../../models/commodity'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { NavBar } from '../../compnents/nav/NavBar'
import { SupplierModal } from '../savebusiness/modal'
import { SupplierModal as SupplierSaveModal } from '../wms/modal'
import { RecipeItemInput } from '../commodity/componment/RecipeItemInput'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { RecipeItemFieldInput } from '../treatment/editor/components/RecipeItemFieldInput'
import { setCurrent } from '../wms/supplierSlice'
import {
  selectCredentials,
  selectInsuranceArray,
} from '../../app/applicationSlice'
import { MedicalModal } from '../commodity/medical/MedicalModal'
import { pinyin } from 'pinyin-pro'
const { Option } = Select
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const ApparatusSaveform = () => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [form] = Form.useForm()

  const [OTCshow, setOTCshow] = useState(true)

  const [tenantSupplierId, settenantSupplierId] = useState(0)

  const [splitTag, setSplitTag] = useState(false)

  const [outher, setOuther] = useState(false)

  const [symbol, setSymbol] = useState(false)

  const [source, setSource] = useState(0)

  const shopNo = useSelector(selectShopNo)

  const [sourceMaterialsCatalogId, setSourceMaterialsCatalogId] = useState()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [ismodalVisible, setIsmodalVisible] = useState(false)

  const [medicalModalInfo, setMedicalModalInfo] = useState<any>()

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [componentItem, setComponentItem] = useState<any>({
    name: '',
    billingCategory: '',
    tenantSupplierName: '',
    transitionScale: '',
    approvalNumber: '',
  })

  const insuranceArray = useSelector(selectInsuranceArray)

  const selectId = useSelector(selectCommodityId)

  const detail: any = useSelector(selectCommodityDetail)

  const [open, setOpen] = useState(false)

  const credentials = useSelector(selectCredentials)

  useEffect(() => {
    if (!localStorage.getItem('time')) {
      localStorage.setItem('time', JSON.stringify(new Date()))
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Durg, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Dosago, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Unit, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Usage, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Frequency, ''))
  }, [])

  useEffect(() => {
    if (!selectId) {
      dispatch(getShopGoodsNo())
    }
  }, [selectId])

  useEffect(() => {
    if (shopNo) {
      form.setFieldsValue({
        no: shopNo,
      })
    }
  }, [shopNo])

  useEffect(() => {
    if (selectId) {
      dispatch(
        getCommodityDetail({
          id: selectId,
          billingCategory: location.state?.record?.billingCategory || '',
          hilistCode: location.state?.record?.hilistCode || '',
          insuranceCode: location.state?.record?.insuranceCode || '',
          mappingId: location.state?.record?.mappingId || '',
          insuAdmdvs: credentials
            ? credentials?.insuranceArray[0]?.insuranceCuntryCode
            : '',
        })
      )
    }
  }, [selectId])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        ...detail.mixInsuranceCatalog,
        hiListSpec: detail.mixInsuranceCatalog?.spec,
        ...detail,
        tax: detail?.tax == -1 ? '' : detail?.tax,
        chrgitmLv: getChrgitmLvName(detail.mixInsuranceCatalog?.chrgitmLv),
        otc: detail.otc !== 10 ? detail.otc : undefined,
        medicarePrice:
          detail.medicarePrice > 0 ? detail.medicarePrice : undefined,
        inventoryAlert:
          detail.inventoryAlert > 0 ? detail.inventoryAlert : undefined,
        splitTag: detail.splitTag > 0 ? true : false,
        forceYearAccount: Number(detail.forceYearAccount) > 0 ? true : false,
        stimulantTag: detail.stimulantTag > 0 ? true : false,
        ephedrineTag: detail.ephedrineTag > 0 ? true : false,
        antibioticTag: detail.antibioticTag > 0 ? true : false,
        basicMedicineTag: detail.basicMedicineTag > 0 ? true : false,
        skinTestTag: detail > 0 ? true : false,
        qualityControlCategory:
          detail.qualityControlCategory < 0
            ? undefined
            : detail.qualityControlCategory,
        splitPrice: detail.splitPrice < 0 ? undefined : detail.splitPrice,
        splitScale: detail.splitScale < 0 ? undefined : detail.splitScale,
        putCategory: detail.putCategory < 0 ? undefined : detail.putCategory,
        stockPrice: detail.stockPrice < 0 ? undefined : detail.stockPrice,

        maintainCategory:
          detail.maintainCategory > 0 ? detail.maintainCategory : 0,
      })
      setComponentItem({
        name: detail.name,
        billingCategory: detail.billingCategory,
        tenantSupplierName: detail?.tenantSupplierName,
        transitionScale: detail?.transitionScale,
        approvalNumber: detail?.approvalNumber,
      })
      if (detail.otc === 10) {
        setOTCshow(false)
      }
      setSplitTag(detail.splitTag)
      settenantSupplierId(detail.tenantSupplierId)
    } else {
      form.setFieldsValue({
        maintainCategory: 0,
        sourceOne: source === 0 ? true : false,
        sourceTwo: source === 3 ? true : false,
      })
    }
  }, [detail])

  useEffect(() => {
    form.setFieldsValue({
      materialsCategory: location.state.materialCategory,
    })
  }, [location.state.materialCategory])

  const YPdata = useSelector(selectCommonCodes(CommonCodeCategory.Durg))
  const YWdata = useSelector(selectCommonCodes(CommonCodeCategory.Dosago))
  const DWdata = useSelector(selectCommonCodes(CommonCodeCategory.Unit))
  const YFdata = useSelector(selectCommonCodes(CommonCodeCategory.Usage))
  const PCdata = useSelector(selectCommonCodes(CommonCodeCategory.Frequency))
  const [SalcData, setSalcData] = useState(DWdata)

  useEffect(() => {
    return () => {
      form.resetFields()
      setComponentItem(undefined)
      dispatch(setSelectId(0))
      dispatch(setCommodityDetail({}))
    }
  }, [])
  useEffect(() => {
    if (!SalcData.length) {
      setSalcData(DWdata)
    }
  }, [DWdata])

  const returnMessage = () => {
    dispatch(setSelectId(0))
    dispatch(setCommodityDetail({}))
    history.push({
      pathname: '/storage/station',
      state: {
        station: '5',
      },
    })
  }

  return (
    <>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={[
            '商品目录',
            JSON.stringify(detail) !== '{}' ? '器械修改' : '器械新增',
          ]}
          backtrace={{
            name: '库房工作站',
            path: '/storage/station',
            state: {
              station: '5',
            },
            routeName: '库房工作站',
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.saveform}>
        <Form
          {...layout}
          form={form}
          name='control-hooks'
          autoComplete='off'
          onFinish={(vs) => {
            const params = selectId
              ? {
                  materialsCatalog: {
                    ...vs,
                    source,
                    sourceMaterialsCatalogId,
                    compoundTag: 0,
                    normalDose: 0,
                    useDays: 0,
                    normalUsemethod: 0,
                    // packSpec: 0,
                    transitionScale: 0,
                    genericName: '',
                    normalFrequency: 0,
                    doseCategory: 0,
                    otc: vs.otc || 0,
                    splitTag: vs.splitTag ? 1 : 0,
                    forceYearAccount: vs.forceYearAccount ? 1 : 0,
                    stimulantTag: vs.stimulantTag ? 1 : 0,
                    ephedrineTag: vs.ephedrineTag ? 1 : 0,
                    antibioticTag: vs.antibioticTag ? 1 : 0,
                    basicMedicineTag: vs.basicMedicineTag ? 1 : 0,
                    skinTestTag: vs.skinTestTag ? 1 : 0,
                    splitScale: vs.splitScale ? vs.splitScale : 0,
                    splitPrice: vs.splitPrice ? vs.splitPrice : 0,
                    id: selectId,
                    signInNo: vs.signInNo ? vs.signInNo : '',
                    tenantSupplierId,
                  },
                  saveMappingBody: {
                    billing_category: vs.billingCategory || '',
                    hilist_code: vs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId: medicalModalInfo?.limitId || '',
                    rid: medicalModalInfo?.rid,
                  },
                }
              : {
                  materialsCatalog: {
                    ...vs,
                    source,
                    sourceMaterialsCatalogId,
                    compoundTag: 0,
                    normalDose: 0,
                    useDays: 0,
                    normalUsemethod: 0,
                    // packSpec: 0,
                    transitionScale: 0,
                    genericName: '',
                    normalFrequency: 0,
                    otc: vs.otc || 0,
                    doseCategory: 0,
                    splitTag: vs.splitTag ? 1 : 0,
                    forceYearAccount: vs.forceYearAccount ? 1 : 0,
                    stimulantTag: vs.stimulantTag ? 1 : 0,
                    ephedrineTag: vs.ephedrineTag ? 1 : 0,
                    antibioticTag: vs.antibioticTag ? 1 : 0,
                    basicMedicineTag: vs.basicMedicineTag ? 1 : 0,
                    skinTestTag: vs.skinTestTag ? 1 : 0,
                    splitScale: vs.splitScale ? vs.splitScale : 0,
                    splitPrice: vs.splitPrice ? vs.splitPrice : 0,
                    tenantSupplierId,
                  },

                  saveMappingBody: {
                    billing_category: vs.billingCategory || '',
                    hilist_code: vs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId: medicalModalInfo?.limitId || '',
                    rid: medicalModalInfo?.rid,
                  },
                }
            // if (selectId) {
            //   dispatch(checkAsync({ ...params?.materialsCatalog }))
            //     .then(unwrapResult)
            //     .then((v: any) => {
            //       if (!v?.flag) {
            //         notification.info({
            //           message: '条形码重复',
            //         })
            //       }
            //     })
            // }
            dispatch(saveCommodityAsync({ ...params }))
              .then(unwrapResult)
              .then(() => {
                dispatch(setSelectId(0))
                dispatch(setCommodityDetail({}))
                history.push({
                  pathname: '/storage/station',
                  state: {
                    station: '5',
                  },
                })
              })
          }}
          onFieldsChange={(_, fs) => {
            const name: any = fs.find((f) => isSameName(f, 'name'))
            const drug: any = fs.find((f) => isSameName(f, 'drugCategory'))
            const retailPrice: any = fs.find((f) =>
              isSameName(f, 'retailPrice')
            )
            const splitScale: any = fs.find((f) => isSameName(f, 'splitScale'))
            if (retailPrice && splitScale) {
              if (retailPrice.value && splitScale.value) {
                form.setFieldsValue({
                  splitPrice:
                    retailPrice.value / splitScale.value < 0
                      ? undefined
                      : retailPrice.value / splitScale.value,
                })
              }
            }
          }}
        >
          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsName(location.state.materialCategory)}
              基本信息
            </p>
          </div>
          <Row>
            <Col span={16} style={{ display: 'flex', alignContent: 'center' }}>
              <Form.Item
                name='allStr'
                label='器械搜索'
                labelCol={{
                  span: 3,
                }}
                wrapperCol={{
                  span: 21,
                }}
                style={{ width: '100%' }}
              >
                <RecipeItemInput
                  materialsCategory={location.state.materialCategory}
                  source={source}
                  type={ItemCompletionType.Auto}
                  filters={false}
                  defaultUsingSpecifiedBatch={false}
                  onSelect={(m: any) => {
                    setSource(0)
                    setSplitTag(false)
                    setSourceMaterialsCatalogId(m.id)
                    form.setFieldsValue({
                      ...m,
                      tax: m?.tax == -1 ? '' : m?.tax,
                      source: 1,
                      allStr: m.name,
                      splitTag: 0,
                      billingCategory: m.billingCategory.toString(),
                      maintainCategory:
                        m.maintainCategory > 0 ? m.maintainCategory : 0,
                      stockPrice: null,
                      inventoryAlert:
                        m.inventoryAlert > 0 ? m.inventoryAlert : null,
                    })
                    dispatch(getZjWbCodes(m.name))
                      .then(unwrapResult)
                      .then((v: any) => {
                        form.setFieldsValue({
                          mnemonicCode: v?.pinYin,
                          wubiCode: v?.wuBi,
                        })
                      })
                    setComponentItem({
                      name: m.name,
                      billingCategory: m.billingCategory,
                      tenantSupplierName: m?.tenantSupplierName,
                      transitionScale: m?.transitionScale,
                      approvalNumber: m?.approvalNumber,
                    })
                  }}
                />
              </Form.Item>
              {open && (
                <Popconfirm
                  title='扫条形码 (69码) 快速添加商品'
                  placement='right'
                  disabled
                  visible={open}
                  onConfirm={() => setOpen(false)}
                  showCancel={false}
                  icon={<CheckCircleOutlined />}
                  okText='我知道了'
                >
                  <div style={{ marginTop: 15 }}></div>
                </Popconfirm>
              )}
            </Col>
            <Col span={4}>
              <Form.Item name='sourceOne' valuePropName='checked'>
                <Checkbox
                  style={{ float: 'right' }}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setSource(0)
                    } else {
                      setSource(1)
                    }
                  }}
                >
                  麟康药品库
                </Checkbox>
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Form.Item name="source" valuePropName="checked">
                <Checkbox
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setSource(2);
                    } else {
                      setSource(1);
                    }
                  }}
                >
                  医保药品库
                </Checkbox>
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item
                name='no'
                label='商品编码'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^[0-9a-zA-Z]{1,}$/, 'g'),
                    message: '请输入数字或字母',
                  },
                  {
                    max: 20,
                    message: '最长100位',
                  },
                ]}
              >
                <Input
                  size='middle'
                  allowClear
                  disabled={selectId ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='name'
                label='物资名称'
                rules={[{ required: true }, { max: 200, message: '最长45位!' }]}
              >
                <Input
                  size='middle'
                  allowClear
                  onInput={(e: any) => {
                    dispatch(getZjWbCodes(e.target.value))
                      .then(unwrapResult)
                      .then((v: any) => {
                        form.setFieldsValue({
                          mnemonicCode: v?.pinYin,
                          wubiCode: v?.wuBi,
                        })
                      })
                    // form.setFieldsValue({
                    //   mnemonicCode: pinyin(e.target.value, {
                    //     pattern: 'first',
                    //     toneType: 'none',
                    //     separator: '',
                    //   }).toUpperCase(),
                    // })
                    setComponentItem({
                      ...componentItem,
                      name: e.target.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='mnemonicCode'
                label='助记码'
                rules={[
                  { required: true },
                  {
                    max: 40,
                    message: '最长40位!',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='barCode'
                label='条形码'
                rules={[{ max: 35, message: '最长35位!' }]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='miscNo'
                label='其他编号'
                rules={[{ max: 40, message: '最长45位!' }]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='materialsCategory' label='类别'>
                <Select placeholder='物资类别' allowClear disabled>
                  {Materiales.map((v) => (
                    <Option value={v} key={v}>
                      {getMaterialsName(v)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='drugCategory'
                label='分类'
                rules={[{ required: true }]}
              >
                <Select>
                  <Option value='第一类医疗器械'>第一类医疗器械</Option>
                  <Option value='第二类医疗器械'>第二类医疗器械</Option>
                  <Option value='第三类医疗器械'>第三类医疗器械</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='billingCategory'
                label='开票项目'
                rules={[{ required: true }]}
              >
                <Select
                  placeholder='开票项目'
                  allowClear
                  onChange={(e) => {
                    setComponentItem({
                      ...componentItem,
                      billingCategory: e,
                    })
                  }}
                >
                  {InvoiceBillingCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {BillingCategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='accountUnit'
                label='账簿单位'
                rules={[{ required: true }]}
              >
                <RecipeItemFieldInput
                  field={'accountUnit'}
                  disabled={false}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='spec'
                label='规格'
                rules={[
                  { required: true },
                  { max: 128, message: '最长128位!' },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='model'
                label='型号'
                rules={[
                  { required: true },
                  { max: 128, message: '最长128位!' },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='origin'
                label='产地'
                rules={[
                  { required: true },
                  { max: 128, message: '最长128位!' },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='tenantSupplierName'
                label='生产厂家'
                rules={[{ required: true }]}
              >
                <Input
                  size='middle'
                  allowClear
                  onClick={() => {
                    setIsModalVisible(true)
                  }}
                  onChange={(e: any) => {
                    setComponentItem({
                      ...componentItem,
                      tenantSupplierName: e?.target?.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='wubiCode'
                label='五笔码'
                rules={[
                  {
                    max: 40,
                    message: '最长40位!',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='approvalNumber' label='批准文号'>
                <Input
                  size='middle'
                  allowClear
                  onChange={(e: any) => {
                    setComponentItem({
                      ...componentItem,
                      approvalNumber: e?.target?.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsName(location.state.materialCategory)}
              价格信息
            </p>
          </div>
          <Row gutter={21}>
            <Col span={8}>
              <Form.Item
                name='stockPrice'
                label='进价/元'
                rules={[
                  {
                    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,5})?)$/, 'g'),
                    message: '请输入保留小数五位以内的数',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='retailPrice'
                label='售价/元'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,5})?)$/, 'g'),
                    message: '请输入保留小数五位以内的数',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name="medicarePrice" label="医保限价/元">
                <Input size="middle" allowClear />
              </Form.Item>
            </Col> */}
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              医保信息
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  setSymbol(!symbol)
                }}
              >
                {!symbol ? (
                  <CaretUpFilled style={{ color: '#999999' }} />
                ) : (
                  <CaretDownFilled style={{ color: '#999999' }} />
                )}
              </span>
            </p>
          </div>
          <Row>
            <Col span={8}>
              <Form.Item label='匹配医保'>
                <span
                  style={{ color: '#4783ff', cursor: 'pointer' }}
                  onClick={() => {
                    if (!insuranceArray.length) {
                      notification.error({ message: '请先开通医保！' })
                      return
                    }
                    if (!componentItem.name || !componentItem.billingCategory) {
                      notification.error({ message: '请先完善器械基本信息！' })
                      return
                    }
                    setMedicalModalVisible(true)
                  }}
                >
                  {form.getFieldValue('hilistName') ? '重新匹配' : '匹配'}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='forceYearAccount'
                label='强制历年'
                valuePropName='checked'
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
          </Row>
          {!symbol ? (
            <Row>
              <Col span={8}>
                <Form.Item name='hiListCode' label='医保编码'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='hilistName' label='医保名称'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='chrgitmLv' label='甲乙分类'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='hiListSpec' label='医保规格'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='prodentpName' label='医保厂家'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='pacunt' label='医保单位'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  name='transitionScale'
                  label='医保转换比'

                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item name='hilistPricUplmtAmt' label='医保限价'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='memo' label='备注'>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
              <Col span={8}>
                <Row style={{ marginLeft: '20px' }}>
                  <Col span={21}>
                    <Form.Item name='rewriteHilistCode' label='自定义医保国码'>
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>
                            当您知道此项目的医保国家编码，而医保匹配又搜索不到该项目时，您可以跳过匹配并直接填写医保国家编码。
                          </p>
                          <p>
                            在编码正确的情况下仍可进行医保结算；系统将会优先使用自定义医保国码进行医保结算。
                          </p>
                          <p>
                            请注意：使用自定义医保国码时，编码的限价与甲乙分类将不能正确展示，但不会影响医保刷卡与报销。
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>
              {/*<Col span={1} style={{ marginLeft: '5px' }}>*/}
              {/* */}
              {/*</Col>*/}
              {/* <Col span={8}>
                <Form.Item name='rewriteHilistCode' label='自定义医保国码'>
                  <Input
                    size='middle'
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ marginLeft: '5px' }}>
                <Popover
                  content={
                    <div>
                      <p>
                        当您知道此项目的医保国家编码，而医保匹配又搜索不到该项目时，您可以跳过匹配并直接填写医保国家编码。
                      </p>
                      <p>
                        在编码正确的情况下仍可进行医保结算；系统将会优先使用自定义医保国码进行医保结算。
                      </p>
                      <p>
                        请注意：使用自定义医保国码时，编码的限价与甲乙分类将不能正确展示，但不会影响医保刷卡与报销。
                      </p>
                    </div>
                  }
                  placement='bottom'
                  title='说明'
                >
                  <QuestionCircleTwoTone />
                </Popover>
              </Col> */}
            </Row>
          ) : (
            <></>
          )}

          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              标志信息
            </p>
          </div>
          <Row>
            <Col span={5}>
              <Form.Item
                name='splitTag'
                label='是否拆零'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  defaultChecked={splitTag}
                  onChange={() => {
                    // dispatch(getSplitTag({ materialsCatalogId: detail?.id }))
                    setSplitTag(!splitTag)
                  }}
                />
              </Form.Item>
            </Col>

            {splitTag || detail.splitTag === true ? (
              <>
                <Col span={6}>
                  <Form.Item
                    name='splitUnit'
                    label='拆零单位'
                    rules={[{ required: true }]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      showSearch
                      optionFilterProp='children'
                    >
                      {SalcData.map((v: any, i) => {
                        return (
                          <Option value={v.name} key={v.id}>
                            {v.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='splitScale'
                    label='拆零比例'
                    rules={[
                      { required: true },
                      {
                        pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                        message: '请输入数字',
                      },
                    ]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Input size='middle' allowClear />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='splitPrice'
                    label='拆零单价/元'
                    rules={[{ required: true }]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Input size='middle' allowClear />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>

          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsName(location.state.materialCategory)}
              其他信息
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  setOuther(!outher)
                }}
              >
                {!outher ? (
                  <CaretUpFilled style={{ color: '#999999' }} />
                ) : (
                  <CaretDownFilled style={{ color: '#999999' }} />
                )}
              </span>
            </p>
          </div>
          {!outher ? (
            <Row>
              {/* <Col span={8}>
                <Form.Item
                  name="medicareCode"
                  label="医保器械注册号"
                  rules={[{ max: 40, message: "最长40位!" }]}
                >
                  <Input size="middle" allowClear />
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item
                  name='tradeRemark'
                  label='注册商标'
                  rules={[{ max: 128, message: '最长128位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name='packSpec'
                  label='包装'
                  rules={[
                    { required: true },
                    { max: 128, message: '最长128位!' },
                  ]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='productionLicence'
                  label='生产许可证'
                  rules={[{ max: 128, message: '最长128位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row style={{ marginLeft: '20px' }}>
                  <Col span={21}>
                    <Form.Item
                      name='tax'
                      label='项目税率'
                      rules={[
                        {
                          pattern: new RegExp(/^(0(\.\d{1,2})?|1(\.0{1,2})?)$/),
                          message: '请输入0~1之间的数字，支持小数点后两位',
                        },
                      ]}
                    >
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>
                            如果您接入了电子发票，且需要控制每个项目的税率，可以在此处填写该项目的税率，如果您属于免税机构，则不需要填写。
                          </p>
                          <p>
                            如果您需要批量修改所有项目的税率，也可与我们的服务人员联系。
                          </p>
                          <p>格式为：0~1，保留2位小数点，如0.03</p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {/* <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsName(location.state.materialCategory)}
              医保信息
              <span
                style={{ float: "right" }}
                onClick={() => {
                  setSymbol(!symbol);
                }}
              >
                {!symbol ? <CaretUpFilled /> : <CaretDownFilled />}
              </span>
            </p>
          </div>
          {!symbol ? (
            <Row>
              <Col span={8}>
                <Form.Item
                  name="medicareCode"
                  label="医保匹配码"
                  rules={[{ max: 40, message: "最长40位!" }]}
                >
                  <Input
                    size="middle"
                    placeholder="药品名称/助记码/条形码"
                    allowClear
                    style={{ width: "640px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )} */}

          <Row gutter={20}>
            <Col span={12}>
              <Button
                htmlType='button'
                style={{ float: 'right' }}
                onClick={() => {
                  if (location.state?.record?.copyFlag) {
                    dispatch(
                      logoutOrenableCommodity(location?.state?.record?.id)
                    )
                      .then(unwrapResult)
                      .then(() => {
                        returnMessage()
                      })
                  } else {
                    returnMessage()
                  }
                }}
              >
                {location.state?.record?.copyFlag ? '删除' : '取消'}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ float: 'left' }}
              >
                完成
              </Button>
            </Col>
          </Row>
        </Form>
        <SupplierModal
          supplierCategory={1}
          visible={isModalVisible}
          switchModal={() => {
            setIsModalVisible(false)
            setIsmodalVisible(true)
          }}
          onOk={() => {
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          onRecord={(record: any) => {
            settenantSupplierId(record.id)
            form.setFieldsValue({
              tenantSupplierName: record.companyName,
            })
          }}
        />
        <SupplierSaveModal
          visible={ismodalVisible}
          supplierCategory={1}
          onOk={() => {
            setIsmodalVisible(false)
            dispatch(setCurrent(1))
            setIsModalVisible(true)
            dispatch(setSelectId(0))
          }}
          onCancel={() => {
            setIsmodalVisible(false)
            setIsModalVisible(true)
            dispatch(setSelectId(0))
          }}
        />
      </div>
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalInfo(item)
          form.setFieldsValue({
            hiListCode: item.hiListCode,
            hilistName: item.hilistName,
            chrgitmLv: item.chrgitmLv,
            hiListSpec: item.spec,
            prodentpName: item.prodentpName,
            pacunt: item.pacunt,
            // transitionScale: item.conversion,
            hilistPricUplmtAmt: item.hilistPricUplmtAmt,
          })
          notification.success({ message: '匹配成功！' })
          setMedicalModalVisible(false)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: insuranceArray.length
            ? insuranceArray[0].insuranceCode
            : '',
        }}
      />
    </>
  )
}
