/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 14:25:29
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-26 15:10:02
 */
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  selectUserDepartmentId,
  selectUserId,
} from '../../app/applicationSlice'
import { NavBar } from '../../compnents/nav/NavBar'
import { CategoryName, getflagCategory } from '../../models/category'
import { DateTimeFormat } from '../../models/datetime'
import { Department } from '../../models/department'
import { User } from '../../models/user'
import { getStorage, selectStorage } from '../durgsystem/durgsystemSlice'
import {
  DepartmentDate,
  savePutstorage,
  selectDepartData,
} from '../putstorage/putstorageSlice'
import {
  getUserName,
  selectstoreId,
  setSelectId,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'

import styles from '../savebusiness/Savebusiness.module.css'

const { Option } = Select

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export const DebitOut = () => {
  const history = useHistory()

  const location: any = useLocation()

  const [form] = Form.useForm()

  const [Text, setText] = useState('新增')

  const [filterPutStorage, setfilterPutStorage] = useState<any[]>([])

  const dispatch = useDispatch()

  const Id = useSelector(selectstoreId)

  const durgsystemData = useSelector(selectStorage)

  const departmentData = useSelector(selectDepartData)

  const userData = useSelector(sleectUserData)

  const userId = useSelector(selectUserId)

  const departmentId = useSelector(selectUserDepartmentId)

  useEffect(() => {
    dispatch(getStorage({ current: 1, state: 1 }))
    dispatch(DepartmentDate({ current: 1, size: 1000 }))

    if (userId || departmentId) {
      dispatch(getUserName(departmentId))
      form.setFieldsValue({
        departmentId: Number(departmentId),
        userId,
      })
    }
  }, [])

  useEffect(() => {
    if (Id) {
      history.push({
        pathname:
          location.state.category === '10'
            ? '/InventoryDetail'
            : location.state.category === '8'
            ? '/InventoryStock'
            : '/putlist',
        state: { category: location.state.category, selectId: Id },
      })
      dispatch(setSelectId(0))
    }
  }, [Id])

  useEffect(() => {
    if (location.state.detail) {
      const detail = location.state.detail
      setText('修改')
      if (JSON.stringify(detail) !== '{}') {
        form.setFieldsValue({
          storehouseId: detail.storehouseId,
          storehouseName: detail.storehouseName,
          flag: detail.flag,
          acceptStorehouseId: detail.acceptStorehouseId,
          acceptStorehouseName: detail.acceptStorehouseName,
          supplierName: detail.supplierName,
          departmentId: detail.departmentId,
          departmentName: detail.departmentName,
          userId: detail.userId?.toString(),
          userName: detail.userName,
          putNumber: detail.putNumber,
          recepitsDate: moment(detail.recepitsDate),
          remark: detail.remark
        })
        dispatch(getUserName(detail.departmentId))
      }
    } else {
      form.setFieldsValue({
        recepitsDate: moment(),
      })
    }
  }, [location.state.detail])

  return (
    <div>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={[
            Number(location.state.category) < 8 ? '库房业务' : '库存管理',
            CategoryName(location.state.category) as string,
          ]}
          backtrace={{
            name: '入库列表',
            path:
              Number(location.state.category) < 8
                ? '/putstorage'
                : '/InventoryCheck',
            state: { category: location.state.category },
            routeName: '库房工作站',
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.content}>
        <div className={styles.title}>
          <p className={styles.titletext}>
            {Text + CategoryName(location.state.category)}
          </p>
        </div>
        <Form
          {...layout}
          form={form}
          name='control-hooks'
          autoComplete='off'
          onFinish={(values) => {
            const storehouseName =
              (durgsystemData.find(
                (d: any) => d.storehouseId === values.storehouseId
              ) as any)?.storehouseName || ''
            const departmentName =
              (departmentData.find(
                (d: any) => d.id === values.departmentId
              ) as any)?.name || ''
            const userName =
              (userData.find((u: any) => u.id === values.userId) as any)
                ?.name || ''
            const date = moment(values.recepitsDate)
            if (location.state.detail) {
              dispatch(
                savePutstorage({
                  ...values,
                  storehouseName,
                  departmentName,
                  userName,
                  category: location.state.category,
                  recepitsDate: date.format(DateTimeFormat),
                  id: location.state.detail.id,
                })
              )
            } else {
              dispatch(
                savePutstorage({
                  ...values,
                  storehouseName,
                  departmentName,
                  userName,
                  category: location.state.category,
                  recepitsDate: date.format(DateTimeFormat),
                })
              )
            }
            form.resetFields()
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name='storehouseId'
                label='库房名称'
                rules={[
                  {
                    required: true,
                    message: '库房名称不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select
                  placeholder='药房'
                  allowClear
                  disabled={location.state.detail ? true : false}
                  onSelect={(key: any) => {
                    setfilterPutStorage(
                      durgsystemData.filter((v: any) => {
                        return v.storehouseId !== Number(key)
                      })
                    )
                  }}
                >
                  {durgsystemData.map((v: any, i) => {
                    return (
                      <Option value={v.storehouseId} key={i}>
                        {v.storehouseName}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='departmentId'
                label='业务部门'
                rules={[
                  {
                    required: true,
                    message: '业务部门不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select
                  placeholder='业务部门'
                  allowClear
                  showSearch
                  onSelect={(key: any) => {
                    form.setFieldsValue({
                      userId: undefined,
                    })
                    dispatch(getUserName(key))
                  }}
                >
                  {departmentData.map((v: Department) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='userId'
                label='业务人员'
                rules={[
                  {
                    required: true,
                    message: '业务人员不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select showSearch placeholder='业务人员' allowClear>
                  {userData.map((v: User) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='recepitsDate'
                label='业务日期'
                rules={[
                  {
                    required: true,
                    message: '业务日期不能为空,请输入正确的值。',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            {location.state.category == '7' ? (
              <Col span={12}>
                <Form.Item name='remark' label='单据备注'>
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
            {location.state.category == '8' 
            || location.state.category == '9' ? (
              <Col span={12}>
                <Form.Item name='remark' label='备注'>
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
            {location.state.category === '5' ||
            location.state.category === '6' ? (
              <Col span={12}>
                <Form.Item
                  name='supplierName'
                  label='对方单位'
                  rules={[
                    {
                      required: true,
                      message: '对方单位不能为空,请输入正确的值。',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {Text === '修改' ? (
              <Col span={12}>
                <Form.Item
                  name='putNumber'
                  label={CategoryName(location.state.category) + '单号'}
                >
                  <Input disabled></Input>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  float: 'right',
                }}
              >
                保存
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType='button'
                onClick={() => {
                  history.go(-1)
                }}
                style={{
                  float: 'left',
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
