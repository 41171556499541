/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-17 17:52:49
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { RecevingParams } from '../../../services/receving'
import * as service from '../../../services/receving'
import { listExportBody, listExport } from '../../../services/storedetail'
interface Receving {
  Receving: []
  Total: 0
  Current: 1
  pageLoading: false
}

const initialState: Receving = {
  Receving: [],
  Total: 0,

  Current: 1,
  pageLoading: false,
}

const recevingSlice = createSlice({
  name: 'recevingSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.Receving = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})


export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = recevingSlice.actions


export function getReceiveList(params: RecevingParams): RootThunk {
  return api(service.getReceive(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}


// 导出
export const getReceiveListExport = createAsyncThunk<
  void,
  RecevingParams,
  RootThunkApi
>('receiving/exportExcel', async (params, api) => {
  return sendAsync(service.receiveListExport(params), api)
})

export const selectRecevingData = (state: RootState) => state.receving.Receving
export const selectTotal = (state: RootState) => state.receving.Total

export const selectCurrent = (state: RootState) => state.receving.Current

export const selectPageLoading = (state: RootState) =>
  state.receving.pageLoading


export default recevingSlice.reducer
