import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {} from 'antd'
import { useDispatch } from 'react-redux'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { RecevingParams } from '../../../services/receving'
export const ReceivingQuery = (props: {
  onValueChange: (queries: RecevingParams) => void
  exportExcel: () => void
}): ReactElement => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [values, setValues] = useState('')

  const { Option } = Select

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.ThisYear)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入供应商名称/助记码'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>

            <Form.Item name='category' noStyle>
              <Select
                placeholder='业务类型'
                allowClear
                style={{ width: '200px' }}
                onChange={() => form.submit()}
              >
                <Option value='1'>业务类型-购入入库</Option>
                <Option value='2'>业务类型-购入退库</Option>
              </Select>
            </Form.Item>

            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='挂号时间-今天'
              initialValue={DateRangeType.ThisYear}
              onChange={() => form.submit()}
              allowClear={false}
            />
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                props?.exportExcel()
              }}
            >
              导出
            </Button>
          </Space>
          {/* <Button
          type="primary"
          onClick={() => props.onValueChange(form.getFieldsValue())}
        >
          打印
        </Button> */}
        </Row>
      </Form>
    </>
  )
}
