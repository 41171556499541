/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2025-02-09 10:38:14
 */

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Select, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableList,
} from "../../../../compnents/list/EditableList";
import { pitestQueryParams } from "../../../../services/pitestover";
import { getpeopleList, getPitestList, getPitestSubmit, selectCurrent, selectPageLoading, selectPeopleList, selectPitestData, selectTotal, setCurrent, setPageLoading } from "../pitestover/pitestSlice";
import { Columns } from "./columns";
import { PitestQuery } from "./Query";
import styles from "./pitest.module.css";
import moment from "moment";
import { DateTimeFormat, DateTimeFormatSimple } from "../../../../models/datetime";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../../app/store";
import { getPietestStateName, PietestStateOptions } from "../../../../models/pitest";

export const Pitest = (props: {
  value?: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm()

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectPitestData);

  const [size, setSize] = useState(10);

  const pageLoadingRX = useSelector(selectPageLoading);

  const [visible, setVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const { Option } = Select

  const [detail, setDetail] = useState<any>({})

  const [queries, setQueries] = useState<pitestQueryParams>();
  useEffect(() => {
    dispatch(getpeopleList())
  }, [])

  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getPitestList({ ...queries, current: Current, state: 2, size }));
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [queries, size, Current,]);

  useEffect(() => {
    if (props.value) {
      dispatch(setPageLoading(true))
      dispatch(getPitestList({ ...queries, current: Current, state: 2, size }));
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [props.value])
  return (
    <>
      <div className={styles.content}>
        <PitestQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v });
            dispatch(setCurrent(1));

          }}
        />
        <EditableList
          className={styles.pitest}
          loading={pageLoadingRX}
          rowKey={(_, i) => `${i}}`}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={Columns(size * (Current - 1), (v, id) => {
            setDetail(v)
            form.setFieldsValue({
              patientName: v?.patientName,
              registrationTime: moment(v?.registrationTime).format(DateTimeFormat),
              result: v?.result?.toString()
            })
            setVisible(true)
          })}
        />

        <Modal
          title="皮试登记"
          visible={visible}
          centered
          width={500}
          onCancel={() => {
            form.resetFields()
            setVisible(false)
            setDetail({})
          }}
          confirmLoading={loading}
          onOk={form.submit}
          destroyOnClose
        >
          <Form
            form={form}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            onFinish={(vs) => {
              dispatch(getPitestSubmit({ result: vs?.result, id: detail?.id }))
              .then(unwrapResult)
              .then(() => {
                dispatch(getPitestList({ ...queries, current: Current, state: 2, size }));
                notification.success({
                  message: '操作成功',
                })
                form.resetFields()
                setVisible(false)
                setDetail({})
              })
            }}
          >
            <Form.Item 
                name='patientName'
                label='皮试人员'
                rules={[{ required: true}]}
              >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='registrationTime'
              label='皮试开始时间'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='result'
              label='皮试结果'
              rules={[{ required: true, message: '请选择皮试结果' }]}
            >
              <Select
                placeholder='皮试结果'
                allowClear
              >
                {PietestStateOptions?.map((v:any) => {
                  return (
                    <Option value={v} key={v}>
                      {getPietestStateName(v?.toString())}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};
