/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-30 10:43:42
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-21 10:36:09
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as S from '../../services/appointment'

export interface AddAppointmentInfoType {
  appointmentDate?:string
  appointmentDoctorId?:string
  appointmentDepartmentId?:string
    appointmentStartTime?:string
    appointmentEndTime?:string
    scheduleId?:string
}
const initialState = {
  settingVisibile: false,
  dayTime: '',
  appointmentTabBar: '1',
  btnLoading: false,
  isUpdate: false,
  addAppointmentInfo:{} as AddAppointmentInfoType
}

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setSettingVisibile(state, action) {
      state.settingVisibile = action.payload
    },
    setDayTime(state, action) {
      state.dayTime = action.payload
    },
    setAppointmentTabBar(state, action) {
      state.appointmentTabBar = action.payload
    },
    setBtnLoading(state, action) {
      state.btnLoading = action.payload
    },
    setIsUpdate (state, action) {
      state.isUpdate = action.payload
    },
    setAddAppointmentInfo(state,action){
      state.addAppointmentInfo = action.payload
    }
  },
})
// 预约设置
export const appointmentSettingAsync = createAsyncThunk<
  void,
  S.AppointmentSettingParams,
  RootThunkApi<void>
>('appointment/appointmentSettingAsync', async (body, api) => {
  return sendAsync(S.appointmentSetting(body), api)
})

// 预约设置 回显
export const appointmentSettingDetailAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('appointment/appointmentSettingAsync', async (_, api) => {
  return sendAsync(S.appointmentSettingDetail(), api)
})

// 预约设置医生
export const appointmentSettingDoctorsAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('appointment/appointmentSettingAsync', async (_, api) => {
  return sendAsync(S.appointmentSettingDoctors(), api)
})

// 预约列表
export const appointmentListAsync = createAsyncThunk<
  void,
  S.appointmentListParams,
  RootThunkApi<void>
>('appointment/appointmentListAsync', async (params, api) => {
  return sendAsync(S.appointmentList(params), api)
})

// 取消预约
export const cancelAppointmentAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('appointment/cancelAppointmentAsync', async (params, api) => {
  return sendAsync(S.cancelAppointment(params), api)
})

// 确认
export const sureAppointmentAsync = createAsyncThunk<void, any, RootThunkApi<void>>(
  'appointment/sureAppointmentAsync',
  async (data, api) => {
    return sendAsync(S.sureAppointment(data), api)
  }
)


export const getAppointDoctorList = createAsyncThunk<void, S.AppointDoctorListParams, RootThunkApi<void>>(
  'appointment/getAppointDoctorList',
  async (data, api) => {
    return sendAsync(S.AppointDoctorList(data), api)
  }
)

//  日 周 预约
export const dayWeekAsync = createAsyncThunk<
  void,
  S.dayWeekParams,
  RootThunkApi<void>
>('appointment/dayWeekAsync', async (params, api) => {
  return sendAsync(S.dayWeek(params), api)
})

// 月 数据
export const monthAppointmentAsync = createAsyncThunk<
  void,
  S.dayWeekParams,
  RootThunkApi
>('appointment/monthAppointmentAsync', async (params, api) => {
  return sendAsync(S.monthAppointment(params), api)
})


// 停诊
export const getScheduleDeactivatedAsync = createAsyncThunk<
  void,
  S.ScheduleDeactivatedParams,
  RootThunkApi
>('appointment/getScheduleDeactivatedAsync', async (params, api) => {
  return sendAsync(S.scheduleDeactivatedAsync(params), api)
})

// 临时排班
export const getScheduleAdditionalAsync = createAsyncThunk<
  void,
  S.ScheduleAdditionalParams,
  RootThunkApi
>('appointment/getScheduleDeactivatedAsync', async (params, api) => {
  return sendAsync(S.ScheduleAdditionalAsync(params), api)
})

// 排班时间
export const getAppointmentScheduleTimeAsync = createAsyncThunk<
  void,
  S.AppointmentScheduleTimeParams,
  RootThunkApi
>('appointment/getAppointmentScheduleTimeAsync', async (params, api) => {
  return sendAsync(S.AppointmentScheduleTimeAsync(params), api)
})

// 停诊删除接口
export const getDeleteDeactivatedAsync = createAsyncThunk<
  void,
  S.DeleteDeactivatedParams,
  RootThunkApi
>('appointment/getDeleteDeactivatedAsync', async (params, api) => {
  return sendAsync(S.DeleteDeactivatedAsync(params), api)
})


export const {setIsUpdate, setBtnLoading,setSettingVisibile,setDayTime,setAppointmentTabBar,setAddAppointmentInfo } = appointmentSlice.actions

export const selectSettingVisibile = (state: RootState) =>
  state.appointment.settingVisibile

  export const selectDayTime = (state: RootState) =>
  state.appointment.dayTime

  export const selectAppointmentTabBar = (state: RootState) =>
  state.appointment.appointmentTabBar
  
  export const selectBtnLoading = (state: RootState) =>
  state.appointment.btnLoading

  export const selectIsUpdate= (state: RootState) =>
  state.appointment.isUpdate

  export const selectAddAppointmentInfo= (state: RootState) =>
  state.appointment.addAppointmentInfo

export default appointmentSlice.reducer
