/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2025-01-22 11:14:08
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { notification } from 'antd'
import _ from 'lodash'
import { api, CREDENTIAL_KEY, sendAsync } from '../.././app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import {fromMaterialJson, Material, MedicalItem, PriceChangeDetail} from '../../models/material'
import { List, Page, pageOf } from '../../models/page'
import { Pager } from '../../models/pagination'
import {
  CatalogBody,
  commodityDetail,
  commoditylogoutOrEnable,
  commodityLogoutOrEnableList,
  commodityPage,
  commodityPageParams,
  commoditySave,
  commoditySubmitBody,
  commoditytExcel,
  DelCodeMedicalBody,
  delCodeMedicalList,
  detailParams,
  examinetreatExcel,
  getGoodsNo,
  getImportExcel,
  getLinKangdurgstore,
  getMaterialsList,
  getServiceNo,
  ImportExcelParams,
  linkangParams,
  logoutOrEnableBody,
  MedicalListBody,
  revokeCatalog,
  saveCodeMedicalList,
  saveLinkangDurg,
  saveMedicalCodeBody,
  updateCatalog,
  getMedicarePageFn,
  getMedicarePageFnParams,
  saveMedicareFn,
  saveMedicareFnParams,
  searchCategoryFn,
  addCategoryFn,
  addCategoryFnParams,
  deleteCategoryFn,
  deleteCategoryFnParams,
  commodityExportExcel,
  getDirectoryUploadFn,
  getDirectoryUploadFnParams,
  getSplitTagFn,
  getSplitTagFnParams,
  getLimitDirectoryUploadFnParams,
  getLimitDirectoryUploadFn,
  getInsuranceOperationList,
  getInsuranceMzList,
  getSelfPayList,
  queryNatyPreparedFn,
  queryZfblCategoryFn,
  ZjWbCodes,
  copyWZMLCatalog,
  commodityPageNew,
  lastEditMaterialsOperation, lastEditTreatmentOperation, PriceChangBody, getLimitPriceChangeList,
  getRegistiongOrTreatmentOperatioEditLog,
  getPaymentOperatioEditLog,
  getMaterialIdsByParam,
  MaterialIdsByParam,
  checkSave,
  checkSubmitBody
} from '../../services/commodity'
import { detailCheck } from '../../services/config'

const initialState = {
  CommodityData: [],
  Total: 0,
  Current: 1,
  selectId: 0,
  CommodityDetail: {},
  YPData: [],
  ZBData: [],
  JXData: [],
  PCData: [],
  YFData: [],
  ShopNo: 0,
  saveCurrent: 1,
  saveTotal: 0,
  saveData: [],
  pageLoading: false,
}

//导入药品
export const LinKangDurgAsync = createAsyncThunk<
  void,
  linkangParams,
  RootThunkApi<void>
>('/commodity/LinKangDurgSave', async (params, api) => {
  return sendAsync(getLinKangdurgstore(params), api)
})

//导入标准药品
export const saveLinKangDurgAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('/commodity/saveDurg', async (Ids, api) => {
  return sendAsync(saveLinkangDurg(Ids), api)
})

export const commodityLogoutOrEnableListAsync = createAsyncThunk<
  void,
  logoutOrEnableBody,
  RootThunkApi<void>
>('commodity/logouorEnableList', async (body, api) => {
  return sendAsync(commodityLogoutOrEnableList(body), api)
})

export const getZjWbCodes = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('commodity/getZjWbCodes', async (params, api) => {
  return sendAsync(ZjWbCodes(params), api)
})

export const batchAddPlanAsync = createAsyncThunk<
  void,
  commodityPageParams,
  RootThunkApi<void>
>('commodity/cureAddPlanAsync', async (body, api) => {
  return sendAsync(commodityPage(body), api)
})

export const commoditySlice = createSlice({
  name: 'commodity',
  initialState,
  reducers: {
    setData(state, action) {
      state.CommodityData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setCommodityDetail(state, action) {
      state.CommodityDetail = action.payload
    },
    setSelectId(state, action) {
      state.selectId = action.payload
    },
    setShopNo(state, action) {
      state.ShopNo = action.payload
    },
    setSaveCurrent(state, action) {
      state.saveCurrent = action.payload
    },
    setSaveTotal(state, action) {
      state.saveTotal = action.payload
    },
    setSaveData(state, action) {
      state.saveData = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LinKangDurgAsync.fulfilled, (state, action: any) => {
      state.saveData = action.payload.records
      state.saveTotal = action.payload.total
    })
    builder.addCase(saveLinKangDurgAsync.fulfilled, (state, action: any) => {
      notification.success({
        message: action.payload,
        duration: 3,
      })
    })
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setCommodityDetail,
  setSelectId,
  setShopNo,
  setSaveCurrent,
  setSaveData,
  setSaveTotal,
  setPageLoading,
} = commoditySlice.actions

//分页
export function pageCommodity(params: commodityPageParams): RootThunk {
  return api(commodityPageNew(params), (data: any, dispatch) => {
    const records = data.records.map((v: any) => {
      return {
        ...v,
        mappingId: v.mappingId > 0 ? v.mappingId : '',
      }
    })
    const total = data.total
    dispatch(setTotal(total))
    dispatch(setData(records))
    dispatch(setPageLoading(false))
  })
}

export const getDrugSearchList = createAsyncThunk<
  void,
  commodityPageParams,
  RootThunkApi
>('/commodity/getDrugSearchList', async (body, api) => {
  return sendAsync(commodityPageNew(body), api)
})

// 导入
export const getCommodityImportExcel = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/commodity/importExcel', async (body, api) => {
  return sendAsync(getImportExcel(body), api)
})

// 诊疗导入 excel
export const getexaminetreatExcel = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/commodity/getexaminetreatExcel', async (body, api) => {
  return sendAsync(examinetreatExcel(body), api)
})

// 商品导入 excel
export const getcommoditytExcel = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/commodity/getcommoditytExcel', async (body, api) => {
  return sendAsync(commoditytExcel(body), api)
})

// 最近一次修改
export const lastEditMaterialsOperationAsync = createAsyncThunk<
  void,
  { id: number },
  RootThunkApi
  >('/commodity/lastEditMaterialsOperation', async (params, api) => {
    return sendAsync(lastEditMaterialsOperation(params), api)
})

export const lastEditTreatmentOperationAsync = createAsyncThunk<
  void,
  { id: number },
  RootThunkApi
>('/commodity/lastEditTreatmentOperation', async (params, api) => {
  return sendAsync(lastEditTreatmentOperation(params), api)
})

export const lastRegistiongOrTreatmentOperatioEditLog = createAsyncThunk<
  void,
  { id: number },
  RootThunkApi
>('/commodity/registiongOrTreatmentOperatioEditLog', async (params, api) => {
  return sendAsync(getRegistiongOrTreatmentOperatioEditLog(params), api)
})

export const lastPaymentOperatioEditLog = createAsyncThunk<
  void,
  { id: number },
  RootThunkApi
>('/commodity/lastPaymentOperatioEditLog', async (params, api) => {
  return sendAsync(getPaymentOperatioEditLog(params), api)
})

export const pageCommodityAsync = createAsyncThunk<
  Page<Material>,
  commodityPageParams,
  RootThunkApi
>('Commodity/page', async (params, api) => {
  return sendAsync(commodityPage(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((m) => fromMaterialJson(m))
        .value()
    )
  )
})

//启用注销
export const logoutOrenableCommodity = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('commodity/start', async (id, api) => {
  return sendAsync(commoditylogoutOrEnable(id), api)
})


//物资目录复制
export const copyCatalogAsync = createAsyncThunk<void, any, RootThunkApi<void>>(
  'commodity/start',
  async (id, api) => {
    return sendAsync(copyWZMLCatalog(id), api)
  }
)


//新增
export const saveCommodityAsync = createAsyncThunk<
  void,
  commoditySubmitBody,
  RootThunkApi<void>
>('commodity/save', async (body, api) => {
  return sendAsync(commoditySave(body), api)
})

// 检测重复
export const checkAsync = createAsyncThunk<
  void,
  checkSubmitBody,
  RootThunkApi<void>
>('commodity/checkAsync', async (body, api) => {
  return sendAsync(checkSave(body), api)
})

// 匹配医保目录
export const getMedicalListAsync = createAsyncThunk<
  List<MedicalItem>,
  MedicalListBody,
  RootThunkApi<void>
>('commodity/getMaterialsList', async (body, api) => {
  const data = {
    ...body,
    hilistLmtpricType: JSON.parse(localStorage.getItem(CREDENTIAL_KEY) || '{}')
      ?.insuranceArray[0]?.hilistLmtpricType,
  }
  return sendAsync(getMaterialsList(data), api)
})
// 查询医保手术目录
export const getInsuranceOperationListAsync = createAsyncThunk<
    List<MedicalItem>,
    MedicalListBody,
    RootThunkApi<void>
    >('commodity/getMaterialsList', async (body, api) => {
  const data = {
    ...body
  }
  return sendAsync(getInsuranceOperationList(data), api)
})

//
export const getLimitPriceChangeListAsync = createAsyncThunk<
  List<PriceChangeDetail>,
  PriceChangBody,
  RootThunkApi<void>
>('commodity/getLimitPriceChangeList', async (body, api) => {
  const data = {
    ...body
  }
  return sendAsync(getLimitPriceChangeList(data), api)
})

// 查询民族药品目录
export const getInsuranceMzListAsync = createAsyncThunk<
    List<MedicalItem>,
    MedicalListBody,
    RootThunkApi<void>
    >('commodity/getInsuranceMzList', async (body, api) => {
  const data = {
    ...body
  }
  return sendAsync(getInsuranceMzList(data), api)
})
// 查询自付比例目录
export const getSelfPayListAsync = createAsyncThunk<
    List<MedicalItem>,
    MedicalListBody,
    RootThunkApi<void>
    >('commodity/getSelfPayListAsync', async (body, api) => {
  const data = {
    ...body
  }
  return sendAsync(getSelfPayList(data), api)
})
// 保存医保目录
export const saveCodeMedicalListAsync = createAsyncThunk<
  void,
  saveMedicalCodeBody,
  RootThunkApi<void>
>('commodity/saveCodeMedicalList', async (body, api) => {
  return sendAsync(saveCodeMedicalList(body), api)
})

// 删除匹配目录
export const delCodeMedicalListAsync = createAsyncThunk<
  void,
  DelCodeMedicalBody,
  RootThunkApi<void>
>('commodity/delCodeMedicalList', async (body, api) => {
  return sendAsync(delCodeMedicalList(body), api)
})

// 目录对照上传-获取|解析发送报文
export const updateCatalogAsync = createAsyncThunk<
  string,
  CatalogBody,
  RootThunkApi<void>
>('commodity/updateCatalog', async (body, api) => {
  return sendAsync(updateCatalog(body), api, true)
})

// 目录对照撤销-获取|解析发送报文
export const revokeCatalogAsync = createAsyncThunk<
  string,
  CatalogBody,
  RootThunkApi<void>
>('commodity/revokeCatalog', async (body, api) => {
  return sendAsync(revokeCatalog(body), api, true)
})

//详情
export function getCommodityDetail(params: detailParams): RootThunk {
  return api(commodityDetail(params), (data: any, dispatch) => {
    dispatch(setCommodityDetail(data))
  })
}

//麟康药品
export const getLinKangDurgAsync = createAsyncThunk<
  Page<Material>,
  linkangParams,
  RootThunkApi
>('commodity/LinKangDurgStora', async (params, api) => {
  return sendAsync(getLinKangdurgstore(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((m) => fromMaterialJson(m))
        .value()
    )
  )
})
// 判断药品拆零
export const getSplitTag = createAsyncThunk<
  any,
  getSplitTagFnParams,
  RootThunkApi
>('commodity/getSplitTag', async (params, api) => {
  return sendAsync(getSplitTagFn(params), api)
})

//药品商品编码
export function getShopGoodsNo(): RootThunk {
  return api(getGoodsNo(), (data: any, dispatch) => {
    dispatch(setShopNo(data))
  })
}

//诊疗商品编码
export function getServerGoodsNo(): RootThunk {
  return api(getServiceNo(), (data: any, dispatch) => {
    dispatch(setShopNo(data))
  })
}
// 获取医保目录列表
export const getMedicarePage = createAsyncThunk<
  any,
  getMedicarePageFnParams,
  RootThunkApi
>('confirm/getMedicarePage', async (params, api) => {
  return sendAsync(getMedicarePageFn(params), api).then((data: any) => {
    return {
      current: data.current,
      size: data.size,
      total: data.total,
      items: _.chain(data.records)
        .map((r) => r)
        .value(),
    }
  })
})

// 医保目录导入
export const saveMedicare = createAsyncThunk<
  void,
  saveMedicareFnParams[],
  RootThunkApi<void>
>('/commodity/saveMedicare', async (params, api) => {
  return sendAsync(saveMedicareFn(params), api)
})
// 类别查询
export const searchCategory = createAsyncThunk<any, void, RootThunkApi<void>>(
  '/commodity/searchCategory',
  async (_, api) => {
    return sendAsync(searchCategoryFn(), api)
  }
)
// 类别新增/编辑
export const addCategory = createAsyncThunk<
  void,
  addCategoryFnParams[],
  RootThunkApi<void>
>('/commodity/addCategory', async (params, api) => {
  return sendAsync(addCategoryFn(params), api)
})
// 类别删除
export const deleteCategory = createAsyncThunk<
  void,
  deleteCategoryFnParams,
  RootThunkApi<void>
>('/commodity/deleteCategory', async (params, api) => {
  return sendAsync(deleteCategoryFn(params), api)
})

// 导出
export const commodityExportExcelAsync = createAsyncThunk<
  void,
  commodityPageParams,
  RootThunkApi
>('/commodity/commodityExportExcelAsync', async (params, api) => {
  return sendAsync(commodityExportExcel(params), api)
})
// 医保目录下载
export const getDirectoryUpload = createAsyncThunk<
string,
  getDirectoryUploadFnParams,
  RootThunkApi
>('/commodity/getDirectoryUpload', async (body, api) => {
  return sendAsync(getDirectoryUploadFn(body), api)
})
// 民族药品目录下载
export const queryNatyPreparedByPageAsync = createAsyncThunk<
    string,
    getDirectoryUploadFnParams,
    RootThunkApi
    >('/commodity/queryNatyPreparedByPageAsync', async (body, api) => {
  return sendAsync(queryNatyPreparedFn(body), api)
})
// 医保自付比例目录下载
export const queryZfblCategoryAsync = createAsyncThunk<
    string,
    getDirectoryUploadFnParams,
    RootThunkApi
    >('/commodity/queryZfblCategoryAsync', async (body, api) => {
  return sendAsync(queryZfblCategoryFn(body), api)
})
// 医保限价目录下载
export const getLimitDirectoryUpload = createAsyncThunk<
    string,
    getLimitDirectoryUploadFnParams,
    RootThunkApi
    >('/commodity/getLimitDirectoryUpload', async (body, api) => {
  return sendAsync(getLimitDirectoryUploadFn(body), api)
})

// 价格标签打印参数
export const getMaterialIdsByParamAsync = createAsyncThunk<
    string,
    MaterialIdsByParam,
    RootThunkApi
    >('/commodity/getMaterialIdsByParamAsync', async (params, api) => {
  return sendAsync(getMaterialIdsByParam(params), api)
})

export const selectCommodityData = (state: RootState) =>
  state.commodity.CommodityData

export const selectorTotal = (state: RootState) => state.commodity.Total

export const selectCurrent = (state: RootState) => state.commodity.Current

export const selectCommodityDetail = (state: RootState) =>
  state.commodity.CommodityDetail

export const selectShopNo = (state: RootState) => state.commodity.ShopNo

export const selectCommodityId = (state: RootState) => state.commodity.selectId

export const selectSaveData = (state: RootState) => state.commodity.saveData

export const selectSaveCurrent = (state: RootState) =>
  state.commodity.saveCurrent

export const selectSaveTotal = (state: RootState) => state.commodity.saveTotal

export const selectPageLoading = (state: RootState) =>
  state.commodity.pageLoading

export default commoditySlice.reducer
