import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, notification, Row, Select, Space, Table } from 'antd'
import { sum } from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { DateTimeFormat, DateTimeFormatSimpleOnly, DateTimeFormatSimplePrintCharge } from '../../../models/datetime'
import { PrintTemplateCategory } from '../../../models/print'
import { getOrderCategoryName } from '../../../models/toBeCompletedTreatment'
import { CompletedTreatmentParams } from '../../../services/completedTreatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { getPrintZXDData } from '../../treatment/editor/recipeEditorSlice'
import { ExcuteDetailModal } from '../toBeCompletedTreatment/excuteDetailModal'
import { CompletedTreatmentColumns } from './column'
import { getCompletedTreatmentList } from './completedTreatmentSlice'
import { CompletedTreatmentQuery } from './Query'
import styles from '../toBeCompletedTreatment/excuteDetailModal.module.css'

export const CompletedTreatment = (props: {
  patientId?: string
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [total, setTotal] = useState<any>()

  const [data, setData] = useState<any[]>([])

  const [id, setId] = useState<any>()

  const [queries, setQueries] = useState<CompletedTreatmentParams>({
    current: 0,
    size: 10,
    state: 1,
    startTime: moment().format(DateTimeFormatSimpleOnly),
    endTime: moment().format(DateTimeFormatSimpleOnly),
  })

  const [patientId, setPatientId] = useState<any>()

  const [recipeId, setRecipeId] = useState<any>()

  const [treatmentId, setTreatmentId] = useState<any>()

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [printZXDData, setPrintZXDData] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  useEffect(() => {
    if (!queries.current) return
    setPageLoading(true)
    dispatch(
      getCompletedTreatmentList({ ...queries, patientId: props.patientId })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
        setTotal(res.total)
      })
      .finally(() => setPageLoading(false))
    !props.patientId &&
      sessionStorage.setItem(
        '/medicalSation_CompletedTreatment',
        JSON.stringify(queries)
      )
  }, [queries])

  const getReciptPrintList = (recipeId?: any) => {
    dispatch(getPrintZXDData(recipeId))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }

  const exportExcel = () => {
    const theader = [
      '门诊号',
      '患者姓名',
      '项目',
      '部位',
      '数量',
      '频次',
      '用法',
      '每次用量',
      '天数',
      '金额',
      '甲/乙',
      '备注',
      '上次执行人',
      '上次执行时间',
    ]
    const filterVal = [
      'outpatientNo',
      'patientName',
      'treatmentServiceName',
      'treatmentParts',
      'num',
      'normalFrequency',
      'normalUsemethod',
      'normalDose',
      'useDays',
      'drugAmount',
      'orderCategory',
      'remark',
      'latelyOperatorName',
      'latelyTime',
    ]
    dispatch(
      getCompletedTreatmentList({ ...queries, patientId: props.patientId })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const drugAmountSum = sum(
          res?.records?.map((v: any) => Number(v.drugAmount))
        )
        const allData = [
          ...res?.records,
          {
            patientName: '合计',
            drugAmount: drugAmountSum,
          },
        ]
        const data = allData
          .map((v: any) => ({
            ...v,
            outpatientNo: Number(v.outpatientNo) > 0 ? v.outpatientNo : '-',
            treatmentServiceName: v?.treatmentServiceName
              ? v?.treatmentServiceName
              : '-',
            num: v?.num ? v?.num : '-',
            normalFrequency: v?.normalFrequency ? v?.normalFrequency : '-',
            normalUsemethod: v?.normalUsemethod ? v?.normalUsemethod : '-',
            normalDose: Number(v.normalDose) > 0 ? v.normalDose : '-',
            useDays: v.useDays ? v.useDays : '-',
            drugAmount: v.drugAmount >= 0 ? v.drugAmount?.toFixed(2) : '-',
            orderCategory: v.orderCategory
              ? getOrderCategoryName(v.orderCategory)
              : '-',
            remark: v?.remark ? v?.remark : '-',
            remainNum: v?.remainNum ? v?.remainNum : '-',
            latelyOperatorName: v?.latelyOperatorName
              ? v?.latelyOperatorName
              : '-',
            latelyTime: v?.latelyTime
              ? moment(v.latelyTime).format(DateTimeFormatSimplePrintCharge)
              : '-',
            treatmentParts : v?.treatmentParts ? v?.treatmentParts : '-'
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '已完成诊疗',
            autoWidth: true,
          })
        })
      })
  }

  return (
    <Col
      style={{
        height: props.patientId ? 'calc(100% - 20px)' : ' calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CompletedTreatmentQuery
          dataList={data}
          onValueChange={(v) => {
            setQueries({ ...v })
          }}
          patientId={props.patientId}
          export={() => {
            exportExcel()
          }}
        />

        <EditableList
          loading={pageLoading}
          page={{
            items: data,
            current: queries.current || 1,
            size: queries.size || 10,
            total: total,
          }}
          columns={CompletedTreatmentColumns((text, t) => {
            if (text === 'execute') {
              setIsModalVisible(true)
              setId(t?.id)
            } else if (text == 'print') {
              if (t?.recipeId) {
                setPatientId(t?.patientId)
                setRecipeId(t?.recipeId)
                setTreatmentId(t?.treatmentId)
                getReciptPrintList(t?.recipeId)
                setPrintTemplateCategory(PrintTemplateCategory.Zxd)
              }else{
                notification.info({
                  message: '暂无可打印的处方',
                })
              }
            }
          }, (queries.size || 10) * ((queries.current || 1) - 1))}
          onChangePage={(current: any, size: any) => {
            setQueries({
              ...queries,
              current,
              size,
            })
          }}
          onRow={(r) => ({
            onDoubleClick: () => {
              dispatch(
                traceRoute({
                  name: '执行记录',
                  path: '/excuteDetail',
                  query: `id=${r.id}`,
                  state: {
                    tag: 1,
                  },
                })
              )
              setId(r.id)
            },
          })}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10} align="center">
                  {Number(sum(data?.map((v: any) => Number(v.drugAmount))))  >= 0 ? sum(data?.map((v: any) => Number(v.drugAmount)))?.toFixed(2) : '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>
      <ExcuteDetailModal
        isModalVisible={isModalVisible}
        id={id}
        onCancel={() => {
          setIsModalVisible(false)
          setId('')
        }}
        onOk={() => {
          setIsModalVisible(false)
          setId('')
        }}
      />

      <PrintTemplateModal
        patientId={patientId}
        printZXDData={printZXDData}
        pagePrintFlag={'zxdPrint'}
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        recipeId={recipeId}
        treatmentId={treatmentId}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
    </Col>
  )
}
