/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 16:02:16
 * @LastEditors: K
 * @LastEditTime: 2022-10-09 09:27:30
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Dropdown, Form, Input, Menu, Modal, Radio, Row, Select, Space, Tag } from 'antd';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../app/store';
import { ThemeContext } from '../../theme/ThemeContext';
import { getPatientLabels } from '../patient/list/patientListSlice';
import styles from './MembersManagementPage.module.css'
import { getAddMembersOperation } from './MembersManagementPageSlice';
// import { getPatientList } from '../../services/patient';
import { getPatientDetail, getPatientList, selectPatients } from '../registration/register/registerSlice';
import { SearchOutlined } from '@ant-design/icons';
import { getGenderName } from '../../models/user';
import { getAge } from '../../utils/StringUtils';
interface AddMembersModalProps {
  visible: boolean
  btnType: boolean
  onCancel: () => void
  onOk: (data: any) => void
}

export const AddMembersModal = ({
  visible,
  btnType,
  onCancel,
  onOk,
}: AddMembersModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm()

  const { CheckableTag } = Tag;

  const { Option } = Select

  const { TextArea } = Input;

  const theme = useContext(ThemeContext)

  const [labelList, setLabelList] = useState<any>([])

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [operation, setOperation] = useState<any>([])

  const [btnLoading, setBtnLoading] = useState(false)

  const [
    isPatientNameOverlayVisible,
    setIsPatientNameOverlayVisible,
  ] = useState(false)

  const [keyword, setKeyword] = useState('')
  
  const [keywords, setKeywords] = useState('')

  const patients = useSelector(selectPatients)

  const patientOverlay = (
    <Menu>
      {patients.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            form.setFieldsValue({
              patientId: p.id,
              memberName: p.name,
              memberPhone: p.phone,
              sexType: String(p.sex),
              ageMonth: p.ageMonth,
              age: p.ageYear,
              birthday: p.birthday,
            });
            setKeywords(p.name);
            setIsPatientNameOverlayVisible(false);
          }}
        >
          <Space>
            <span>{p.name}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{getAge(p.ageYear, p.ageMonth, "岁")}</span>
            <span>{p.phone}</span>
            <span>{p.id}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  const updateBirthday = () => {
    if (form) {
      const year = parseInt(form.getFieldValue("age")) || 0;
      const month = parseInt(form.getFieldValue("ageMonth")) || 0;
      if (year !== 0 || month !== 0) {
        const months = year * 12 + month;
        const birthday = moment()
          .subtract(months, "months")
          .format("YYYY/MM/DD");
        form.setFieldsValue({ birthday });
      }
    }
  };

  const updateYearsAndMonths = () => {
    if (form) {
      const date = moment(form.getFieldValue("birthday"));
      if (!date.isValid()) {
        return;
      }
      const duration = moment.duration(moment().diff(date));
      if (duration.years() < 0 || duration.months() < 0) {
        return;
      }
      form.setFieldsValue({
        ageYear: duration.years(),
        ageMonth: duration.months() == 0 ? 1 : duration.months(),
      })
    }
  };

  useEffect(() => {
    setBtnLoading(false)
  }, [btnType])
  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setKeywords('')
      setSelectedTags([])
    } else {
      dispatch(getPatientLabels({ current: 1, size: 50 })).then(unwrapResult).then((res: any) => setLabelList(res))
      dispatch(getAddMembersOperation()).then(unwrapResult).then((res: any) => setOperation(res))
    }
  }, [visible])

  useEffect(() => {
    dispatch(getPatientList(keyword || keywords))
  }, [dispatch, keyword, keywords])

  return (
    <Modal
      visible={visible}
      title='新增会员'
      width={700}
      cancelText='取消'
      okText='确定'
      confirmLoading={btnLoading}
      onCancel={onCancel}
      onOk={form.submit}
    >
      <Form
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 17 }}
        autoComplete="off"
        initialValues={{
          balanceMoney: '0',
          createUser: Number(JSON.parse(localStorage.getItem('credentials') || '{}')?.userId)
        }}
        onFinish={(values) => {
          const res = /(^[0-9]*\.([0-9]{1}\d*)$)|(^[0-9]*$)/
          if (!res.test(values.balanceMoney)) return
          setBtnLoading(true)
          onOk({
            ...values,
            memberName: keywords,
            age: moment(values.birthday).format("YYYY/MM"),
            ageMonth: undefined,
          });
        }}

      >
        <Form.Item label="姓名" name="memberName" rules={[{ required: true }]}>
          <Dropdown
            overlay={patientOverlay}
            visible={isPatientNameOverlayVisible}
          >
            <Input
              allowClear
              placeholder="请输入姓名"
              prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
              value={keywords}
              onFocus={() => {
                dispatch(getPatientList(keywords))
                setIsPatientNameOverlayVisible(true)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsPatientNameOverlayVisible(false)
                }, 300)
              }}
              onClick={() => {
                setIsPatientNameOverlayVisible(true)
              }}
              onChange={(e) => {
                setIsPatientNameOverlayVisible(true)
                setKeywords(e.target.value)
                setKeyword('')
              }}
            />
          </Dropdown>
        </Form.Item>
        <Form.Item label="手机号" name="memberPhone" rules={[
          {
            required: true,
            pattern: new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/),
            message: "请输入正确的会员手机号码",
          }
        ]}>
          <Input />
        </Form.Item>
        <Form.Item label="性别" name="sexType" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="1">男</Radio>
            <Radio value="2">女</Radio>
            <Radio value="0">未知</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='年龄' required>
          <Row wrap={false} align='middle' >
            <Form.Item
              name="age"
              noStyle
              dependencies={['ageMonth']}
              rules={[
                {
                  pattern: /^\d{0,3}$/,
                  message: '请输入正确的年龄',
                },
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (!value && !getFieldValue('ageMonth')) {
                      return Promise.reject(new Error('请输入正确的年龄'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                onChange={updateBirthday}
                style={{ maxWidth: '3rem' }}
              />
            </Form.Item>
            <div
              style={{
                color: theme.tc2,
                fontSize: '1rem',
                margin: '0 6px',
              }}
            >
              岁
            </div>
            <Form.Item
              name='ageMonth'
              noStyle
              dependencies={['ageYear']}
              rules={[
                {
                  pattern: /^(0?[0-9]|1[0-1])$/,
                  message: '请输入正确的月份',
                },
              ]}
            >
              <Input
                // readOnly={hasCard}
                // onClick={() => readMessage()}
                onChange={updateBirthday}
                style={{ maxWidth: '3rem' }}
              />
            </Form.Item>
            <div
              style={{
                color: theme.tc2,
                fontSize: '1rem',
                margin: '0 6px',
              }}
            >
              月
            </div>
            <Form.Item
              name='birthday'
              noStyle
              style={{ flex: 1 }}
              rules={[{ max: 16, message: '最多16个字' },
              {
                pattern: /^\d{4}\/\d{1,2}\/\d{1,2}$/,
                message: '年龄格式为YYYY/MM/DD',
              },]}
            >
              <Input
                onChange={updateYearsAndMonths}
              // readOnly={hasCard}
              // onClick={() => readMessage()}
              />
            </Form.Item>
          </Row>
        </Form.Item>
        <Form.Item label="生日" name="birthday" >
          <Input />
        </Form.Item>
        <Form.Item label="标签" name="labelIds" >
          {labelList.map((item: any) => (
            <CheckableTag
              style={{ marginBottom: 5, border: '1px solid #d2d2d2', borderRadius: 3 }}
              key={item.id}
              checked={selectedTags.indexOf(item.id) > -1}
              onChange={(checked: any) => {
                const nextSelectedTags = checked ? [...selectedTags, item.id] : selectedTags.filter(t => t !== item.id);
                form.setFieldsValue({
                  labelIds: nextSelectedTags.join(',')
                })
                setSelectedTags(nextSelectedTags)
              }}
            >
              {item.name}
            </CheckableTag>
          ))}
        </Form.Item>
        <Form.Item label="储值金" name="balanceMoney" rules={[
          {
            pattern: new RegExp(/(^[0-9]*\.([0-9]{1}\d*)$)|(^[0-9]*$)/),
            message: "请输入正确的的数字",
          }
        ]}>
          <Input suffix={<div className={styles.iptText}>元</div>} />
        </Form.Item>
        <Form.Item label="操作员" name="createUser">
          <Select>
            {
              operation.length && operation.map((v: any) => (
                <Option key={v.userId} value={v.userId}>{v.userName}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label="备注" name="remarks">
          <TextArea showCount maxLength={100} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

