/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-28 14:34:32
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-29 15:07:11
 */
import { Col, Row, Tabs } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { EditableList } from '../../../compnents/list/EditableList';
import { NavBar } from '../../../compnents/nav/NavBar';
import { Label } from '../../../compnents/widgets/Label';
import styles from '../MembersManagementPage.module.css'
import { TopUpRecordModalColumns, detailColumns } from "../columns";
import { useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../app/store';
import { getMembersBaseInfo, getMembersTopUpRecord } from '../MembersManagementPageSlice';

export const MembersManagementDetailPage = (): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const location: any = useLocation()

  const [selectKey, setSelectKey] = useState('0')

  const [params, setParams] = useState({ current: 0, size: 10 });

  const [page, setPage] = useState({
    items: [],
    total: 10
  });

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [membersInfo, setMembersInfo] = useState<any>({})

  const getPage = () => {
    setPageLoading(true)
    dispatch(getMembersTopUpRecord({ ...params, memberId: location?.state?.membersId, type: Number(selectKey) }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    params.current && getPage()
  }, [params, selectKey])

  useEffect(() => {
    setParams({ ...params, current: 1 })
    dispatch(getMembersBaseInfo({ memberId: location?.state?.membersId })).then(unwrapResult).then((res: any) => setMembersInfo(res))
  }, [location?.state?.membersId])
  return (
    <Col className={styles.detailWrap}>
      <NavBar
        where={['会员管理', '会员信息']}
        backtrace={{
          name: '会员管理',
          path: location?.state?.isMenu ? '/member' : '/statistics/outpatient',
          state: {
            state: '2'
          },
          routeName: location?.state?.isMenu ? '会员管理' : '患者管理',
        }}
      />
      <Col className={styles.contentWrap}>
        <div className={styles.contentTitle}>
          <Label label='会员基础信息' />
        </div>

        <Row style={{ fontWeight: 'bold', fontSize: '16px' }}>
          <Col span={3} style={{ textAlign: 'right' }}>手机号：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.memberPhone}</Col>
          <Col span={3} style={{ textAlign: 'right' }}>姓名：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.memberName}</Col>
          <Col span={3} style={{ textAlign: 'right' }}>账户余额：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.balanceMoney?.toFixed(2)}</Col>
        </Row>
        <Row style={{ margin: '20px 0 20px 0 ', }}>
          <Col span={3} style={{ textAlign: 'right' }}>实际充值金额：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.rechargeAmount?.toFixed(2)}</Col>
          <Col span={3} style={{ textAlign: 'right' }}>充值次数：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.payNumber}</Col>
          <Col span={3} style={{ textAlign: 'right' }}>实际消费金额：</Col>
          <Col span={4} style={{ textAlign: 'left' }}>{membersInfo?.monetary?.toFixed(2)}</Col>
        </Row>

        <Tabs

          activeKey={selectKey}
          defaultActiveKey='0'
          onChange={(v: any) => setSelectKey(v)}>
          <Tabs.TabPane tab="充值记录" key="0" >
            <EditableList
              className={styles.patientLable}
              loading={pageLoading}
              scroll={{ x: "28rem", y: "25rem" }}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              onChangePage={(current, size) => setParams({ ...params, current, size: size || 10 })}
              columns={TopUpRecordModalColumns(params.size * (params.current - 1), 1)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="消费记录" key="1">
            <EditableList
              className={styles.patientLable}
              loading={pageLoading}
              scroll={{ x: "28rem", y: "25rem" }}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              onChangePage={(current, size) => setParams({ ...params, current, size: size || 10 })}
              columns={detailColumns(params.size * (params.current - 1))}
            />
          </Tabs.TabPane>

        </Tabs>
      </Col>
    </Col>
  );
}
