// ZDFY(1, "自动发药"), PSGL(2, "皮试管理"), GBXGJG(3,"关闭修改价格"),
// GBKDYSTX(4,"关闭开单医生提醒"), ZDRJSJ(5,"指定日结时间"),
// JZRQSD(6,"结转日期设定"), MRZFFS(7,"默认支付方式");

export enum Checks {
  Zdfy = 1,
  Psgl = 2,
  Gbxgjg = 3,
  Gbkdysstx = 4,
  Zdrjsj = 5,
  Jzrqsd = 6,
  Mrzffs = 7,
  Zjjs = 8,
  Lkcky = 9,
  LKSC = 10,
  ZNFZ = 11,
  LJWPSSF = 14, //拦截未皮试的收费
  ZCYNDJTED = 20, //中草药年度帖均额度
  YBJJNDZFXE = 21, //医保基金年度支付限额
  YBLZYLFNDZFXE = 22, //医保列支医疗费年度支付限额
  RCRTB = 23, //人次人头比
  CJNDFYXE = 24, //次均年度费用限额
  RJNDFYXE = 25, //人均年度费用限额
  SJHM = 26, //手机号码
  TSPL = 27, // 推送频率
  CFJDYCFJE = 28, // 处方笺是否打印处方金额
  CFJDYCJHPCH = 33, //处方笺打印厂家与批次号
  CFJDYBZ = 35, //处方笺打印备注
  FYFS = 17, //发药方式
  ISEDIT = 18, //是否允许修改病历
  JEML = 31, //金额抹零
  HZFZBJ = 34, //患者复诊标记
  CFJDYBLH = 32, //处方笺是否打印病历号条码
  ZDJSTS = 36, //自动计算天数
  CFJDYZS = 37, //处方笺打印主诉
  CFJDYZZL = 39, //处方笺打诊疗
  CFJDYZCL = 40, //处方笺打材料
  CFJDYZZC = 41, //收据展示折扣
  CFJDYDAYS = 53, //打印天数
  FPXZ = 42,
  LSDJ = 43,
  SCCS = 44,
  PCHYXQ = 45,
  DJDW = 46,
  FZLWTGBTH = 47,
  JGLXDH = 48,
  JYYSKQ = 49,
  OVERLIMITSIGN = 50,
  PRINTCABINENO = 52,
  AUTODAILY = 54,
  ZDJSTSBCH=55,
  AdvancedMedical = 51,//高级病历 新医美
  AI = 101//AI服务
}

export const CheckOptions = [
  Checks.Zdfy,
  Checks.Psgl,
  Checks.Gbxgjg,
  Checks.Gbkdysstx,
  Checks.Zdrjsj,
  Checks.Jzrqsd,
  Checks.Mrzffs,
  Checks.ZNFZ,
]

export const CheckNames = [
  '自动发药',
  '皮试管理',
  '关闭修改价格(医生)',
  '关闭开单医生提醒',
  '指定日结时间',
  '结转日期设定',
  '默认支付方式',
  '零库存开药',
]

export function getCheckName(check: Checks): string {
  if (check < 0 || check >= CheckNames.length) {
    return ''
  }
  return CheckNames[check]
}

export interface Settinglable {
  id?: string
  type?: string
  value?: string
  tenentId?: string
}

export class Settings {
  static Registration = class {
    static readonly SkipPrinting = 'settings.registration.printing.skip'
  }

  static AI = class {
    //是否开通AI功能
    static readonly MAIN_SWITCH = 'settings.ai.main_switch'
  }
  static Treatment = class {
    // 接诊医生提醒
    static readonly DoNotWarnDoctorMismatch =
      'settings.treatment.do-not-warn-doctor-mismatch'
    // 诊间结算
    static readonly Settlement = 'settings.treatment.settlement'
    // 医美高级病例
    static readonly highTreatmentTemplate = 'settings.treatment.highTreatmentTemplate'

  }

  static Recipe = class {
    static readonly UseSpecifiedBatch = 'settings.recipe.use-specified-batch'
    static readonly IgnoreStorage = 'settings.recipe.ignore-storage'
    static readonly TcmUseSpecifiedBatch =
      'settings.recipe.tcm-use-specified-batch'
    static readonly TcmIgnoreStorage = 'settings.recipe.tcm-ignore-storage'
    static readonly ChangePrice = 'settings.recipe.change-price'
    static readonly AutoChangePrice = 'settings.recipe.auto-change-price'
    static readonly AutoChangePriceAfter = 'settings.recipe.auto-change-price-after'
  }

  static Payment = class {
    static readonly DefautMethod = 'settings.payment.default-method'
  }

  static Printing = class {
    static readonly Preview = 'settings.printing.preview'

    static readonly HasTreatmentTemplate =
      'settings.printing.has-treatment-template'
  }
}

export interface MedicelListValue {
  cityHospitalNb: string
  countryHospitalNb: string
  endTime: string
  id: number
  insuranceCode: string
  insuranceName: string
  isFaceCall: number
  linkPort: string
  recordType: number
  startTime: string
  sysStatus: number
  tenantId: number
  tenantStatus: number
  ybIp: string
  ybMac: string
  yidiHospitalNb: string
}

export interface insuranceCodeValue {
  createTime: string
  insuranceCode: string
  insuranceName: string
  isFaceCall: number
  linkPort: string
  recordType: number
  status: number
  updateTime: string
}

export enum AccountType {
  ALL = '2',
  YAC = '0',
  NAC = '1',
}

export const AccountTypes = [AccountType.ALL, AccountType.YAC, AccountType.NAC]

export const getAccountTypeName = (accountType: AccountType) => {
  switch (accountType) {
    case AccountType.ALL:
      return '全部'
    case AccountType.YAC:
      return '对账一致'
    case AccountType.NAC:
      return '对账不一致'
  }
}
