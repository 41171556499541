import {Gender, registrationCategory } from "./list";
import { RecipeCategory } from "./recipe";

  export interface piTest {
    id: number;
    registrationState: registrationCategory;
    gender: Gender;
    category: RecipeCategory;
    startTime:number,
    actualTime: number;
    drugName: string;
    outpatientNo: number;
    patientAge: number;
    patientId: number;
    patientMnemonicCode: string;
    patientName: string;
    patientPhone: string;
    patientSex: number;
    presetTime: string;
    receiveInAdvance: number;
    receiveTag: number;
    registrationDepartmentId: number;
    registrationDepartmentName: string;
    registrationDoctorId: number;
    registrationDoctorName: string;
    registrationFeeServiceId: number;
    registrationPayState: number;
    registrationTime: string;
    result: string;
    settlementTypesOfInsurance: string;
    skinTestId: string;
    state: number;
    status: number;
    tenantId: string;
    testUser: string;
    testUserName: string;
    treatmentDepartmentId: number;
    treatmentDepartmentName: string;
    treatmentDoctorId: number;
    treatmentDoctorName: string;
    treatmentId: number;
    treatmentTime: string;
    realname ?:string,
    recipeState ?:string
  }
  
  export function fromJson(json: any): piTest {
    return {
      id: json.id?.toString() || "",
      // id: json.id,
      gender: json.patientSex,
      registrationState: json.registrationState,
      category: json.category,
      startTime:json.startTime,
      actualTime: json.actualTime,
      drugName: json.drugName,
      outpatientNo: json.outpatientNo,
      patientAge: json.patientAge,
      patientId: json.patientId,
      patientMnemonicCode: json.patientMnemonicCode,
      patientName: json.patientName,
      patientPhone: json.patientPhone,
      patientSex: json.patientSex,
      presetTime: json.presetTime,
      receiveInAdvance: json.receiveInAdvance,
      receiveTag: json.receiveTag,
      registrationDepartmentId: json.registrationDepartmentId,
      registrationDepartmentName: json.registrationDepartmentName,
      registrationDoctorId: json.registrationDoctorId,
      registrationDoctorName: json.registrationDoctorName,
      registrationFeeServiceId: json.registrationFeeServiceId,
      registrationPayState: json.registrationPayState,
      registrationTime: json.registrationTime,
      result: json.result,
      settlementTypesOfInsurance: json.settlementTypesOfInsurance,
      skinTestId: json.skinTestId,
      state: json.state,
      status: json.status,
      tenantId: json.tenantId,
      testUser: json.testUser,
      testUserName: json.testUserName,
      treatmentDepartmentId: json.treatmentDepartmentId,
      treatmentDepartmentName: json.treatmentDepartmentName,
      treatmentDoctorId: json.treatmentDoctorId,
      treatmentDoctorName: json.treatmentDoctorName,
      treatmentId: json.treatmentId,
      treatmentTime: json.treatmentTime,
      realname :json.realname,
      recipeState:json.recipeState,
    };
  }
  
  export function toJson(pitest: piTest): any {
    return {
      id: pitest.id,
      category: pitest.category,
      registrationState: pitest.registrationState,
      patientSex: pitest.gender,
      actualTime: pitest.actualTime,
      startTime:pitest.startTime,
      drugName: pitest.drugName,
      outpatientNo: pitest.outpatientNo,
      patientAge: pitest.patientAge,
      patientId: pitest.patientId,
      patientMnemonicCode: pitest.patientMnemonicCode,
      patientName: pitest.patientName,
      patientPhone: pitest.patientPhone,
      presetTime: pitest.presetTime,
      receiveInAdvance: pitest.receiveInAdvance,
      receiveTag: pitest.receiveTag,
      registrationDepartmentId: pitest.registrationDepartmentId,
      registrationDepartmentName: pitest.registrationDepartmentName,
      registrationDoctorId: pitest.registrationDoctorId,
      registrationDoctorName: pitest.registrationDoctorName,
      registrationFeeServiceId: pitest.registrationFeeServiceId,
      registrationPayState: pitest.registrationPayState,
      registrationTime: pitest.registrationTime,
      result: pitest.result,
      settlementTypesOfInsurance: pitest.settlementTypesOfInsurance,
      skinTestId: pitest.skinTestId,
      state: pitest.state,
      status: pitest.status,
      tenantId: pitest.tenantId,
      testUser: pitest.testUser,
      testUserName: pitest.testUserName,
      treatmentDepartmentId: pitest.treatmentDepartmentId,
      treatmentDepartmentName: pitest.treatmentDepartmentName,
      treatmentDoctorId: pitest.treatmentDoctorId,
      treatmentDoctorName: pitest.treatmentDoctorName,
      treatmentId: pitest.treatmentId,
      treatmentTime: pitest.treatmentTime,
      realname :pitest.realname,
      recipeState :pitest.recipeState,
    };
  }
  


  export enum  pietestState {
    YXTag = '0',
    YX = '1',
    RYX = '2',
    QYX = '3',
    XP = '4',
    MP = '5',
  }
  
  export const PietestStateOptions = [
    pietestState.YXTag,
    pietestState.YX,
    pietestState.RYX,
    pietestState.QYX,
    pietestState.XP,
    pietestState.MP,
  ]
  
  export function getPietestStateName(type: pietestState): string {
    switch (type) {
      case '0':
        return '阴性'
      case '1':
        return '阳性'
      case '2':
        return '弱阳性'
      case '3':
        return '强阳性'
      case '4':
        return '续皮'
      case '5':
        return '免皮'
      default:
        return '-'
    }
  }