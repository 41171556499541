/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:34
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-06 16:53:43
 */
/* eslint-disable react/display-name */
import { QuestionCircleTwoTone } from '@ant-design/icons'
import { Popover, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { getXianzhonglxName } from '../../../models/AnnualCapitalFund'

export const Columns = (page?: any): TableColumnType<any>[] => {
  const noHJPage = page?.filter(
    (v: any) => v?.xianzhonglxmc != '合计' && v?.xianzhonglxmc != '总合计'
  )
  const HJPage = page?.filter((v: any) => v?.xianzhonglxmc == '合计')

  const Hint = (title: string) => (
    <>
      {title}
      <Popover placement="top" content="人次 = 未退费交易的笔数（一个患者当天多次结算人次为1）" >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );
  const HintHead = (title: string) => (
    <>
      {title}
      <Popover placement="top" content={
        <div>
          <p>
            人头 = 未退费交易的人头数。
          </p>
          <p>
            同一个人头可能在不同时期存在多个险种或者医疗类型，故人头数据只有合计数，不做明细计算。
          </p>
        </div>
      } >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );
  return [
    {
      title: '业务类型',
      dataIndex: '',
      width: '6em',
      align: 'center',
      render: (value, row, index) => {
        const obj: any = {
          children:
            value?.xianzhonglxmc == '合计' || value?.xianzhonglxmc == '总合计'
              ? value?.xianzhonglxmc
              : '门诊(含特慢病)',
          props: {
            rowSpan: value?.xianzhonglxmc == '总合计' ? 1 : 0,
            colSpan:
              value?.xianzhonglxmc == '总合计'
                ? 3
                : value?.xianzhonglxmc == '合计'
                ? 2
                : 1,
          },
        }
        if (index == 0 && value?.xianzhonglxmc != '总合计') {
          obj.props.rowSpan = noHJPage?.length
        }

        if (index == noHJPage?.length && value?.xianzhonglxmc != '总合计') {
          obj.props.rowSpan = HJPage?.length
        }
        // if (index == page?.length - 1) {
        //   obj.props.colSpan = 3
        // }
        return obj
      },
    },
    {
      title: '险种',
      dataIndex: '',
      width: '6em',
      align: 'center',
      render: (value, row, index) => {
        if (
          value?.xianzhonglxmc == '总合计' ||
          value?.xianzhonglxmc == '合计'
        ) {
          return {
            children: value?.xianzhonglxmc,
            props: { colSpan: 0 },
          }
        } else {
          let rowSpan = 0
          const arr:any = page?.filter((res: any) => {
            //这里gpsNumber是我需要判断的字段名（相同就合并）
            return res.xianzhonglxmc == value.xianzhonglxmc
          })
          if (
            index == 0 ||
            page?.[index - 1].xianzhonglxmc != value.xianzhonglxmc
          ) {
            rowSpan = arr?.length
          } else {
            rowSpan = 0
          }
          return {
            children: value?.xianzhonglxmc,
            props: { rowSpan: rowSpan },
          }
        }
      },
    },
    {
      title: '类别',
      dataIndex: '',
      width: '6em',
      align: 'center',
      render: (value, row, index) => {
        if (value?.xianzhonglxmc == '总合计') {
          return {
            children: value?.yiliaolbmc,
            props: { colSpan: 0 },
          }
        } else {
          return {
            children: value?.yiliaolbmc,
          }
        }
      },
    },
    {
      title: Hint('人次'),
      key: 'personTime',
      width: '5em',
      align: 'center',
      render: (_, r) => (r?.personTime ? r?.personTime : 0),
    },
    {
      title: HintHead('人头'),
      key: 'headCount',
      width: '5em',
      align: 'center',
      render: (_, r) => (r?.headCount ? r?.headCount : 0),
    },
    {
      title: '人次人头比',
      key: 'personHeadScale',
      width: '6em',
      align: 'center',
      render: (_, r) =>
        isNaN(r?.personHeadScale)?'-':Number(r?.personHeadScale).toFixed(2),
    },
    {
      title: '总额',
      key: 'totalAmount',
      width: '5em',
      align: 'right',
      render: (_, r) => (r?.totalAmount ? r?.totalAmount?.toFixed(2) : '0.00'),
    },
    {
      title: '现金',
      key: 'cashAmount',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.cashAmount ? r?.cashAmount?.toFixed(2) : '0.00',
    },
    {
      title: '个账',
      key: 'gerenzhzc',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.gerenzhzc ? r?.gerenzhzc?.toFixed(2) : '0.00',
    },
    {
      title: '当年账户支出',
      key: 'dangnianzhzc',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.dangnianzhzc ? r?.dangnianzhzc?.toFixed(2) : '0.00',
    },
    {
      title: '历年账户支出',
      key: 'linianzhzc',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.linianzhzc ? r?.linianzhzc?.toFixed(2) : '0.00',
    },
    {
      title: '账户共济支出',
      key: 'gerenzhgjzfje',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.gerenzhgjzfje ? r?.gerenzhgjzfje?.toFixed(2) : '0.00',
    },

    {
      title: '统筹基金',
      key: 'jijinzfze',
      width: '6em',
      align: 'right',
      render: (_, r) =>
        r?.jijinzfze ? r?.jijinzfze?.toFixed(2) : '0.00',
    },
    {
      title: '列支',
      key: 'includedMedicalExpenses',
      width: '5em',
      align: 'right',
      render: (_, r) =>
          r?.includedMedicalExpenses ? r?.includedMedicalExpenses?.toFixed(2) : '0.00',
    },
    {
      title: '公务员医疗补助',
      key: 'gongwuyylbzzjzc',
      width: '5em',
      align: 'right',
      render: (_, r) =>
        r?.gongwuyylbzzjzc ? r?.gongwuyylbzzjzc?.toFixed(2) : '0.00',
    },
    {
      title: '次均',
      key: 'totalAmountPer',
      width: '5em',
      align: 'center',
      render: (_, r) =>
          isNaN(r?.totalAmountPer)?'-':Number(r?.totalAmountPer).toFixed(2),
    },
    {
      title: '人均',
      key: 'totalAmountPerson',
      width: '6em',
      align: 'center',
      render: (_, r) =>
          isNaN(r?.totalAmountPerson)?'-':Number(r?.totalAmountPerson).toFixed(2),
    },
    {
      title: '自理',
      key: 'xianxingzfje',
      width: '5em',
      align: 'center',
      render: (_, r) =>
        r?.xianxingzfje ? r?.xianxingzfje?.toFixed(2) : '0.00',
    },
    {
      title: '全自费金额',
      key: 'quanzifje',
      width: '6em',
      align: 'right',
      render: (_, r) =>
        r?.quanzifje ? r?.quanzifje?.toFixed(2) : '0.00',
    },
    {
      title: '超限金额',
      key: 'chaoxianjzffy',
      width: '6em',
      align: 'right',
      render: (_, r) =>
        r?.chaoxianjzffy ? r?.chaoxianjzffy?.toFixed(2) : '0.00',
    },
    {
      title: '核拨',
      key: 'reimbursementAmount',
      width: '6em',
      align: 'center',
      render: (_, r) =>
        r?.reimbursementAmount ? r?.reimbursementAmount?.toFixed(2) : '0.00',
    },
  ]
}
