import {
  CaretDownFilled,
  PlusSquareFilled,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  selectInsuranceArray,
  selectStationCategory,
  selectUserDepartmentId,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import {
  getRegistrationStateName,
  RegistrationStates,
} from '../../models/registration'
import { StationCategory } from '../../models/user'
import { RegistrationQueryParams } from '../../services/registration'
import { useQuery } from '../../utils/HttpUtils'
import styles from './Query.module.css'
import { readCardEHttp, readCardSHttp } from '../../utils/MedicalUtils'
import { getTradeCode } from '../../models/material'
import { traceRoute } from '../../layouts/layoutSlice'
import { RootDispatch } from '../../app/store'
import { isEditTreatmentAsync } from '../treatment/diagnosis/diagnosisSlice'
import { unwrapResult } from '@reduxjs/toolkit'

export const PatientQuery = ({
  onValueChange,
  today,
  currentTab,
}: {
  onValueChange: (params: RegistrationQueryParams) => void
  today: boolean
  currentTab: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const inputRef = useRef<any>(null)

  const [form] = Form.useForm()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const userName = useSelector(selectUserName)

  const query = useQuery()

  const userId = useSelector(selectUserId)

  const location: any = useLocation()

  const departmentId = useSelector(selectUserDepartmentId)

  const stationCategory = useSelector(selectStationCategory)

  const insuranceArray = useSelector(selectInsuranceArray)

  const [notSpecifiedDoctor, setNotSpecifiedDoctor] = useState(false)

  const [queryFlag, setQueryFlag] = useState<any>()

  const receiveTag = location?.state?.receiveTag

  const dataParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const flag: any =
    localStorage.getItem('notSpecifiedDoctor') == 'true' ? true : false

  useEffect(() => {
    setNotSpecifiedDoctor(flag)
    refresh()
  }, [flag])

  useEffect(() => {
    dispatch(isEditTreatmentAsync())
      .then(unwrapResult)
      .then((res: any) => {
        setQueryFlag(res)
      })
  }, [])

  const submit = (
    values: Record<string, string | undefined>,
    type?: DateRangeType,
    status?: boolean
  ) => {
    const vs = { ...values } as Record<string, string | undefined>
    const ids = {
      treatmentDoctorId:
        vs.treatmentDoctorId && vs.treatmentDoctorId.startsWith('u')
          ? vs.treatmentDoctorId.substring(1)
          : undefined,
      treatmentDepartmentId:
        vs.treatmentDoctorId && vs.treatmentDoctorId.startsWith('d')
          ? vs.treatmentDoctorId.substring(1)
          : undefined,
    }
    const [head, tail] = getDateRange(type)
    
    onValueChange(
      today
        ? {
            ...dataParams,
            ...vs,
            registrationTimeHead: head,
            registrationTimeTail: tail,
            ...ids,
            current: status ? 1 : dataParams.current || 1,
            showUnspecified: flag ? 1 : 0,
          }
        : {
            ...dataParams,
            ...vs,
            ...ids,
            current: status ? 1 : dataParams.current || 1,
          }
    )
  }

  useEffect(() => {
    const tabBar = dataParams.index
    if (queryFlag) {
      if (queryFlag == '2') {
        if (!Number(tabBar) == today) {
          form.setFieldsValue({
            ...dataParams,
            treatmentDoctorId: `u${userId}`,
            receiveTag: receiveTag?.toString(),
          })
        } else {
          form.setFieldsValue({
            treatmentDoctorId: `u${userId}`,
            [DateRangeFieldName]: !today && DateRangeType.Yesterday,
            registrationTimeHead: head,
            registrationTimeTail: tail,
            receiveTag: receiveTag?.toString(),
            // param: '', //防止数据缓存
          })
        }
      }
    }
  }, [queryFlag])

  useEffect(() => {
    // form.resetFields()
    const tabBar = dataParams.index

        if (!Number(tabBar) == today) {
          form.setFieldsValue({
            ...dataParams,
            treatmentDoctorId: dataParams.treatmentDoctorId
              ? `u${dataParams.treatmentDoctorId}`
              : dataParams.treatmentDepartmentId
              ? `d${dataParams.treatmentDepartmentId}`
              : stationCategory === StationCategory.DoctorOrPharmacist
              ? `u${userId}`
              : undefined,
            receiveTag: receiveTag?.toString(),
            // param: '', //防止数据缓存
          })
        } else {
          form.setFieldsValue({
            treatmentDoctorId:
              stationCategory === StationCategory.DoctorOrPharmacist
                ? `u${userId}`
                : undefined,
            [DateRangeFieldName]: !today && DateRangeType.Yesterday,
            registrationTimeHead: head,
            registrationTimeTail: tail,
            receiveTag: receiveTag?.toString(),
            // param: '', //防止数据缓存
          })
        }
    submit(
      form.getFieldsValue(),
      today ? DateRangeType.Today : DateRangeType.Yesterday
    )

    inputRef.current!.focus({
      //获取输入框焦点
      cursor: 'start',
    })
  }, [])


  

  const [head, tail] = getDateRange(
    today ? DateRangeType.Today : DateRangeType.Yesterday
  )

  const refresh = () => {
    submit(form.getFieldsValue(), today ? DateRangeType.Today : undefined, true)
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    if (currentTab)
      form.setFieldsValue({
        receiveTag: receiveTag?.toString(),
      })
  }, [receiveTag, currentTab])

  // 实体卡读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceItem.countryHospitalNb,
          '01306',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        console.log('ddd')
        if (!err) {
          const idCardNumber = data?.netmessage?.split('|')?.[1]
          if (idCardNumber) {
            form.setFieldsValue({
              param: idCardNumber,
            })
          }
        }
      }
    )
  }

  // useEffect(() => {   //搜索框获取焦点
  //   form.setFieldsValue({
  //     param: ''
  //   })
  //   inputRef.current!.focus({
  //     cursor: 'start',
  //   });
  // }, [])
  return (
    <Form
      form={form}
      autoComplete='off'
      onFinish={(values) =>
        submit(values, today ? DateRangeType.Today : undefined)
      }
    >
      <Row
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: '0 15px',
        }}
        justify='space-between'
      >
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              ref={inputRef}
              placeholder='患者姓名/手机号/助记码/病历号'
              prefix={<SearchOutlined />}
              className={styles.input}
              onChange={refresh}
              allowClear
            />
          </Form.Item>
          <Form.Item noStyle name='treatmentDoctorId'>
            <Select
              placeholder='查看范围'
              allowClear
              onChange={refresh}
              style={{ width: '8rem' }}
              disabled={queryFlag == 2 ? true : false}
              // suffixIcon={<CaretDownFilled />}
            >
              <Select.Option value={`u${userId}`}>只看本人</Select.Option>
              <Select.Option value={`d${departmentId}`}>
                只看本科室
              </Select.Option>
            </Select>
          </Form.Item>

          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8.25rem' }}
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={refresh}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {!today && (
            <>
              <Form.Item noStyle name='registrationState'>
                <Select
                  style={{ width: '8rem' }}
                  placeholder='就诊状态'
                  onChange={refresh}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                >
                  {RegistrationStates.map((s) => (
                    <Select.Option key={s} value={s}>
                      {getRegistrationStateName(s)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='receiveTag'
                noStyle
                initialValue={receiveTag?.toString()}
              >
                <Select
                  placeholder='就诊类型'
                  style={{ width: '8.25rem' }}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={refresh}
                >
                  <Select.Option value={'0'}>初诊</Select.Option>
                  <Select.Option value={'1'}>复诊</Select.Option>
                </Select>
              </Form.Item>
              <DateSelect
                style={{ width: '8rem' }}
                labelPrefix=''
                placeholder='挂号时间'
                namePrefix='registrationTime'
                onChange={refresh}
              />
            </>
          )}
          {today && (
            <Form.Item
              name='receiveTag'
              noStyle
              initialValue={receiveTag?.toString()}
            >
              <Select
                placeholder='就诊类型'
                style={{ width: '8.25rem' }}
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={refresh}
              >
                <Select.Option value={'0'}>初诊</Select.Option>
                <Select.Option value={'1'}>复诊</Select.Option>
              </Select>
            </Form.Item>
          )}

          <Button
            type='primary'
            onClick={() => {
              refresh()
            }}
          >
            查询
          </Button>
          {insuranceItem && insuranceItem?.insuranceCode === 'JIANGSU' && (
            <Button
              type='primary'
              onClick={() => {
                readEType()
              }}
            >
              读电子凭证
            </Button>
          )}
          {today && (
            <Button
              type='primary'
              className={styles.create}
              icon={<PlusSquareFilled />}
              onClick={() =>
                dispatch(
                  traceRoute({
                    name: '挂号',
                    path: '/registration',
                    query: 'redirect',
                  })
                )
              }
            >
              快速就诊
            </Button>
          )}
        </Space>
        {today && (
          <Space>
            <Checkbox
              checked={notSpecifiedDoctor}
              onChange={(e) => {
                setNotSpecifiedDoctor(e.target.checked)
                localStorage.setItem(
                  'notSpecifiedDoctor',
                  JSON.stringify(e.target.checked)
                )
              }}
            >
              包含未指定医生
            </Checkbox>
          </Space>
        )}
      </Row>
    </Form>
  )
}
