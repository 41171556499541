import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface commodityPageParams {
  current?: number
  size: number
  allMaterials?: number
  allStr?: string
  materialsCategory?: any
  category?: any
  otc?: number
  state?: number
  tenantSupplierId?: number
  flag?: number
  insuranceCode?: string
  materialsCategoryList?: any
  isFullMatch?: any
  showFlag?: any
  isRestrictUser?: any
}

export interface linkangParams {
  current?: number
  materialsCategory?: number
  size?: number
  name?: string
  insuranceCode?: string
}

export interface commoditySubmitBody {
  materialsCatalog: {
    accountUnit?: string | undefined
    antibioticTag?: number | undefined
    approvalNumber?: string | undefined
    barCode?: string | undefined
    basicMedicineTag?: number | undefined
    billingCategory?: string | undefined
    compoundTag?: number | undefined
    doseCategory?: string | undefined
    doseUnit?: string | undefined
    drugCategory?: string | undefined
    ephedrineTag?: number | undefined
    freezeTemperature?: string | undefined
    genericName?: string | undefined
    id?: number | undefined
    inventoryAlert?: number | undefined
    materialsCategory?: number | undefined
    miscNo?: string | undefined
    mnemonicCode?: string | undefined
    name?: string | undefined
    no?: string | undefined
    normalDose?: number | undefined
    normalFrequency?: string | undefined
    normalUsemethod?: string | undefined
    orderCategory?: number | undefined
    origin?: string | undefined
    otc?: number | undefined
    packSpec?: string | undefined
    productionLicence?: string | undefined
    reportNo?: string | undefined
    retailPrice?: number | undefined
    skinTestTag?: number | undefined
    sourceMaterialsCatalogId?: number | undefined
    spec?: string | undefined
    splitPrice?: number | undefined
    splitScale?: number | undefined
    splitTag?: number | undefined
    splitUnit?: string | undefined
    state?: number | undefined
    stimulantTag?: number | undefined
    forceSelfPayment?: number | undefined
    stockPrice?: number | undefined
    tenantId?: number | undefined
    tenantSupplierId?: number | undefined
    tradeRemark?: string | undefined
    transitionScale?: number | undefined
    useDays?: string | undefined
    tenantSupplierName?: string | undefined
    rewriteHilistCode?: string | undefined
    rules?:any
  }
  saveMappingBody: {
    proviceHilistCode?: string
    billing_category?: string
    hilist_code?: string
    insuranceCode?: string
    is_reimbursement?: string
    limitId?: string
    rid?: string
    limitCount?: number | undefined
  }
}

export interface logoutOrEnableBody {
  ids: string
  state: number
}

//分页
export function commodityPage(params: commodityPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/page`,
    method: 'GET',
    params,
  }
}

export function commodityPageNew(params: commodityPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/goodsManagement`,
    method: 'GET',
    params,
  }
}

// 五笔翻译接口
export function ZjWbCodes(name: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/chineseConverter/doConvert`,
    method: 'GET',
    params: {
      name,
    },
  }
}

//启用
export function commoditylogoutOrEnable(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/logoutOrEnable`,
    method: 'post',
    body: {
      id,
    },
  }
}

// 物资目录复制
export function copyWZMLCatalog(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/copy`,
    method: 'GET',
    params: {
      id,
    },
  }
}

//新增
export function commoditySave(body: commoditySubmitBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/submit`,
    method: 'POST',
    body,
  }
}


export interface checkSubmitBody {
    accountUnit?: string | undefined
    antibioticTag?: number | undefined
    approvalNumber?: string | undefined
    barCode?: string | undefined
    basicMedicineTag?: number | undefined
    billingCategory?: string | undefined
    compoundTag?: number | undefined
    doseCategory?: string | undefined
    doseUnit?: string | undefined
    drugCategory?: string | undefined
    ephedrineTag?: number | undefined
    freezeTemperature?: string | undefined
    genericName?: string | undefined
    id?: number | undefined
    inventoryAlert?: number | undefined
    materialsCategory?: number | undefined
    miscNo?: string | undefined
    mnemonicCode?: string | undefined
    name?: string | undefined
    no?: string | undefined
    normalDose?: number | undefined
    normalFrequency?: string | undefined
    normalUsemethod?: string | undefined
    orderCategory?: number | undefined
    origin?: string | undefined
    otc?: number | undefined
    packSpec?: string | undefined
    productionLicence?: string | undefined
    reportNo?: string | undefined
    retailPrice?: number | undefined
    skinTestTag?: number | undefined
    sourceMaterialsCatalogId?: number | undefined
    spec?: string | undefined
    splitPrice?: number | undefined
    splitScale?: number | undefined
    splitTag?: number | undefined
    splitUnit?: string | undefined
    state?: number | undefined
    stimulantTag?: number | undefined
    forceSelfPayment?: number | undefined
    stockPrice?: number | undefined
    tenantId?: number | undefined
    tenantSupplierId?: number | undefined
    tradeRemark?: string | undefined
    transitionScale?: number | undefined
    useDays?: string | undefined
    tenantSupplierName?: string | undefined
    rewriteHilistCode?: string | undefined
    rules?:any
}

// 检测重复
export function checkSave(body: checkSubmitBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/check`,
    method: 'POST',
    body,
  }
}


export interface detailParams {
  id: number
  billingCategory?: string
  hilistCode?: string
  insuranceCode?: string
  mappingId?: string
  insuAdmdvs?: string
}

//详情
export function commodityDetail(params: detailParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/detail`,
    method: 'GET',
    params,
  }
}

//麟康药品库
export function getLinKangdurgstore(params: linkangParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/list`,
    method: 'GET',
    params,
  }
}
// 判断药品拆零
export interface getSplitTagFnParams {
  materialsCatalogId: string
}
export function getSplitTagFn(params: getSplitTagFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/splitCountCheck`,
    method: 'GET',
    params,
  }
}

//导入标准药品
export function saveLinkangDurg(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/importbatch`,
    method: 'POST',
    params: {
      ids,
    },
  }
}

//商品编码
export function getGoodsNo(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/seq/allocate/GoodsNo`,
    method: 'POST',
  }
}

//诊疗编码
export function getServiceNo(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/seq/allocate/TreatmentServiceNo`,
    method: 'POST',
  }
}

export function commodityLogoutOrEnableList(body: logoutOrEnableBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/logoutbatch`,
    method: 'POST',
    body,
  }
}

// 导入
export interface ImportExcelParams {
  file?: any
}
// 导入
export function getImportExcel(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/log/importexcel`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

// 诊疗导入 excel
export function examinetreatExcel(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/log/importexcel`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

// 商品导入 excel
export function commoditytExcel(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/log/importexcelNew`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

// 最近一次修改
export function lastEditMaterialsOperation(params: { id: number }): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/getMaterialsEditLog`,
    method: 'GET',
    params,
  }
}

export function lastEditTreatmentOperation(params: { id: number }): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/getTreatmentEditLog`,
    method: 'GET',
    params,
  }
}

export function getRegistiongOrTreatmentOperatioEditLog(params: {
  id: number
}): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getRegistiongAndTreatmentOperatioEditLog`,
    method: 'GET',
    params,
  }
}

export function getPaymentOperatioEditLog(params: { id: number }): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getPaymentOperatioEditLog`,
    method: 'GET',
    params,
  }
}
export interface MedicalListBody {
  billingCategory?: string
  current?: number
  hilistCode?: string
  insuranceCode?: string
  itemNameKeyWorld?: string
  aprvno?:any
  prodConvrat?:any
  prodentpNameKeyWorld?: string
  size?: number
  insuAdmdvs?: string
}

// 匹配医保目录
export function getMaterialsList(body: MedicalListBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/searchInsuranceList`,
    method: 'POST',
    body,
  }
}

// 查询医保手术目录
export function getInsuranceOperationList(params: MedicalListBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/surgicalInsuranceCountry/page`,
    method: 'GET',
    params,
  }
}
// 查询民族药品目录
export function getInsuranceMzList(params: MedicalListBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/nationInsuranceCountryPage`,
    method: 'GET',
    params,
  }
}
// 查询自负比例药品目录
export function getSelfPayList(params: MedicalListBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/selfPayServicePage`,
    method: 'GET',
    params,
  }
}

export interface saveMedicalCodeBody {
  billing_category?: string
  hilist_code?: string
  insuranceCode?: string
  is_reimbursement?: string
  local_item_id?: string
  limitId?: string
  rid?: string
  proviceHilistCode?: string
}

// 保存匹配信息
export function saveCodeMedicalList(body: saveMedicalCodeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/saveCatMapping`,
    method: 'POST',
    body,
  }
}

export interface DelCodeMedicalBody {
  insuranceCode?: string
  mapping_id?: string
  medicalType?: number//1:药品  2:诊疗
  localItemId?: number
}

// 删除匹配信息
export function delCodeMedicalList(body: DelCodeMedicalBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/deleteMapping`,
    method: 'POST',
    body,
  }
}

export type serverType = 'requestString' | 'response'

export type baseDataType = {
  address?: string
  areaCode?: string
  cityHospitalNb?: string
  countryHospitalNb?: string
  cycleCode?: string
  endTime?: string
  insuranceCode?: string
  name?: string
  organLevel?: number
  responseString?: string
  tenantCategory?: number
  tenantId?: number
  userId?: string
  userName?: string
  ybIp?: string
  ybMac?: string
  yidiHospitalNb?: string
  insuplcAdmdvs?: string //行政区域编号
}

export interface Catalog {
  baseData: baseDataType
  catMappingIds: any[]
}

export interface CatalogBody {
  serverType: serverType
  body: Catalog
}

export function updateCatalog(CatalogBody: CatalogBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/uploadCatalog_${CatalogBody.serverType}`,
    method: 'POST',
    body: {
      ...CatalogBody.body,
    },
  }
}

export function revokeCatalog(CatalogBody: CatalogBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/revokeCatalog_${CatalogBody.serverType}`,
    method: 'POST',
    body: {
      ...CatalogBody.body,
    },
  }
}

// 获取医保列表
export interface getMedicarePageFnParams {
  current: number
  size: number
  hilistCode: number
  insuranceCode: string
  hilistName: string
  valiFlag: string
}
export function getMedicarePageFn(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/getInsuranceCodeCountryListPage`,
    method: 'GET',
    params,
  }
}

// 医保目录导入
export interface saveMedicareFnParams {
  rid: number
  sellingPrice: number
}
export function saveMedicareFn(body: saveMedicareFnParams[]): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/importbatch/insurance`,
    method: 'POST',
    body,
  }
}

// 类别查询
export function searchCategoryFn(): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentcategory/search`,
    method: 'GET',
  }
}
// 类别新增/编辑

export interface addCategoryFnParams {
  id: number | undefined
  name: string
}
export function addCategoryFn(body: addCategoryFnParams[]): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentcategory/submitList`,
    method: 'POST',
    body,
  }
}
// 类别删除

export interface deleteCategoryFnParams {
  id: number
}
export function deleteCategoryFn(params: deleteCategoryFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentcategory/remove`,
    method: 'POST',
    params,
  }
}

// 商品目录导出
export function commodityExportExcel(params: commodityPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/page/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
// 医保目录下载
export interface getDirectoryUploadFnParams {
  serverType: serverType
  body: {
    baseData: baseDataType
    updt_time: string
    page_num: number
    page_size: number
  }
}
// 民族药品目录下载
export interface queryNatyPreparedFnParams {
  serverType: serverType
  body: {
    baseData: baseDataType
    updt_time: string
    page_num: number
    page_size: number
  }
}
// 自付比例目录下载
export interface queryZfblCategoryFnParams {
  serverType: serverType
  body: {
    baseData: baseDataType
    updt_time: string
    page_num: number
    page_size: number
  }
}
// 医保限价目录下载
export interface getLimitDirectoryUploadFnParams {
  serverType: serverType
  body: {
    baseData: baseDataType
    updt_time: string
    page_num: number
    page_size: number
  }
}
export function getDirectoryUploadFn(
  bodyName: getDirectoryUploadFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryHilist_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
export function queryNatyPreparedFn(
  bodyName: queryNatyPreparedFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryNatyPreparedByPage_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
export function queryZfblCategoryFn(
  bodyName: queryZfblCategoryFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/querySelfpayByPage_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
export function getLimitDirectoryUploadFn(
  bodyName: getLimitDirectoryUploadFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryLmtprcByPage_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface PriceChangBody {
  searchStr?: string
  current?: number
  insuranceCode?: string
  timeHead?: string
  timeTail?: string
  type?: number
  size?: number
}

//
export function getLimitPriceChangeList(params: PriceChangBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/limitPriceChange/list`,
    method: 'GET',
    params,
  }
}

// 价格标签打印获取id
export interface MaterialIdsByParam {
  type?: any
}

export function getMaterialIdsByParam(params: MaterialIdsByParam): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/getMaterialIdsByParam`,
    method: 'GET',
    params,
  }
}


