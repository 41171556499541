import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Pagination, Row, Select, Space } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUserDepartmentId,
  selectUserId,
} from '../../../app/applicationSlice'
import {
  DateRangeHistoryTreatmentTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { RecipeCategory } from '../../../models/recipe'
import { CopyRecipeParams } from '../../../services/recipe'
import {
  CopyTreatmentParams,
  GetTreatmentListParams,
} from '../../../services/treatment'
import { TreatmentBoxList } from './TreatmentBoxList'
import {
  getTreatmentList,
  getTreatmentOldList,
  reset,
  selectTreatmentPage,
} from './treatmentSlice'

export interface TreatmentsRef {
  reset: () => void
}
interface TreatmentsProps {
  historyMessageTag?: any
  recipeCategory?: RecipeCategory
  recipeId?: any
  exact?: boolean
  patientId?: string
  treatmentId?: string //通用病历-中医病历-口腔病历-医嘱处方 展示底部详情
  tempType?: boolean //通用病历-中医病历-口腔病历-医嘱处方 展示底部详情
  showDiagnosis?: boolean
  onCopy?: (
    params: CopyTreatmentParams | CopyRecipeParams,
    tip?: any,
    flag?: number,
    diease?: any
  ) => void
  style?: React.CSSProperties
  medicalRecord?: number
}

const RealTreatments = (
  {
    historyMessageTag,
    recipeCategory,
    recipeId,
    exact = true,
    patientId,
    treatmentId,
    tempType,
    onCopy,
    showDiagnosis = true,
    style = {},
    medicalRecord,
  }: TreatmentsProps,
  ref: React.Ref<TreatmentsRef>
): ReactElement => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const userId = useSelector(selectUserId)

  const departmentId = useSelector(selectUserDepartmentId)

  const page = useSelector(selectTreatmentPage)
  const [params, setParams] = useState<GetTreatmentListParams>({
    current: 0,
    // treatmentTimeHead: head,
    // treatmentTimeTail: tail,
    treatmentDoctorId: userId,
    patientId,
  })

  const paths: string[] = location.pathname.split('/').slice(1, 2)
  const getPage = () => {
    // if (!params.current || !patientId) return
    if (!params.current) return
    const data = {
      ...params,
      recipeCategory: recipeCategory,
      hideRecipeId: recipeId,
      medicalRecord,
    }
    const obj = treatmentId ? { id: treatmentId } : data //通用病历 获取详情数据只传 ID
    dispatch(getTreatmentOldList(obj))
  }

  useEffect(() => {
    // if (!params.current || !patientId) return
    if (!params.current) return
    const data = {
      ...params,
      recipeCategory: recipeCategory,
      hideRecipeId: recipeId,
      medicalRecord,
    }
    const obj = tempType ? { id: treatmentId } : data //详情只需要一个 ID
    dispatch(getTreatmentOldList(obj))
  }, [params, medicalRecord])

  useEffect(() => {
    setParams({
      ...params,
      patientId,
    })
  }, [patientId])

  const [head, tail] = getDateRange(DateRangeType.ThisYear)
  useEffect(() => {
    form.setFieldsValue({
      treatmentTimeHead: head,
      treatmentTimeTail: tail,
      _dateRange: 5,
    })
    form.submit()
    tempType &&
      setParams({
        ...params,
        current: 1,
      })
  }, [])

  useImperativeHandle(ref, () => ({
    reset: () => {
      dispatch(reset())
      setParams({ current: 0, treatmentDoctorId: userId, patientId })
      form.resetFields()
    },
  }))

  return (
    <Col
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      {!tempType && (
        <Form
          form={form}
          autoComplete='off'
          initialValues={{
            id: `u${userId}`,
            scope: 0,
          }}
          onFinish={(values) => {
            const vs = {
              ...params,
              ...values,
              id: undefined,
              scope: undefined,
              treatmentDepartmentId: values.id?.startsWith('d')
                ? departmentId
                : undefined,
              treatmentDoctorId: values.id?.startsWith('u')
                ? userId
                : undefined,
              patientId: values.scope === 0 ? patientId : undefined,
              current: 1,
            }
            setParams(vs)
          }}
        >
          <Row style={{ marginBottom: 20 }} justify='space-between'>
            <Space>
              <Form.Item name='param' noStyle>
                <Input
                  style={{ width: '14rem' }}
                  placeholder='请输入疾病名称或病人信息'
                  prefix={<SearchOutlined />}
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item noStyle name='id'>
                <Select
                  style={{ width: '7.5rem' }}
                  placeholder='查看范围'
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={`u${userId}`}>只看本人</Select.Option>
                  <Select.Option value={`d${departmentId}`}>
                    只看本科室
                  </Select.Option>
                </Select>
              </Form.Item>
              {/* {paths[0] === "treatment" || paths[0] == 'toothPatient' && ( */}
              <Form.Item noStyle name='scope'>
                <Select
                  style={{ width: '12rem' }}
                  placeholder='查看范围'
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={0}>查看病人范围-当前病人</Select.Option>
                  <Select.Option value={1}>查看病人范围-所有病人</Select.Option>
                </Select>
              </Form.Item>
              {/* )} */}
              <DateSelect
                namePrefix='treatmentTime'
                labelPrefix=''
                options={DateRangeHistoryTreatmentTypes}
                placeholder='时间范围'
                style={{ width: '8.5rem' }}
                // initialValue={DateRangeType.ThisYear}
                onChange={form.submit}
              />
            </Space>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Row>
        </Form>
      )}
      <Col style={{ flex: 1, overflow: 'auto' }}>
        <TreatmentBoxList
          historyMessageTag={historyMessageTag}
          page={page}
          exact={exact}
          recipeCategory={recipeCategory}
          showDiagnosis={showDiagnosis}
          onCopy={onCopy}
        />
      </Col>
      {!tempType && (
        <Row justify='end'>
          <Pagination
            style={{ marginTop: 20, marginRight: 30 }}
            total={page.total}
            current={page.current}
            pageSize={page.size}
            onChange={(current, size) =>
              setParams({ ...params, current, size })
            }
          />
        </Row>
      )}
    </Col>
  )
}

export const Treatments = forwardRef(RealTreatments)
